"use strict";

require("core-js/modules/es.array.reduce.js");

require("core-js/modules/es.number.constructor.js");

require("core-js/modules/es.number.max-safe-integer.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* 運費算法：與 meepshop-api 一般結帳流程對齊 */

/* 前後端都沒有阻擋負數運費 */
var getShipmentFee = function getShipmentFee(priceRule, productQuantity) {
  var _priceRule$count;

  if (!priceRule) return null; // 固定運費

  if ((priceRule === null || priceRule === void 0 ? void 0 : priceRule.method) === 0) return Math.max((priceRule === null || priceRule === void 0 ? void 0 : priceRule.fix) || 0, 0);

  var replaceIfNil = function replaceIfNil(val, defaultValue) {
    return !val ? defaultValue : val;
  };

  var price = (_priceRule$count = priceRule.count) === null || _priceRule$count === void 0 ? void 0 : _priceRule$count.reduce(function (totalPrice, rule) {
    var inRange = productQuantity >= replaceIfNil(rule === null || rule === void 0 ? void 0 : rule.min, 0) && productQuantity <= replaceIfNil(rule === null || rule === void 0 ? void 0 : rule.max, Number.MAX_SAFE_INTEGER);
    return inRange ? (rule === null || rule === void 0 ? void 0 : rule.price) || 0 : totalPrice;
  }, 0);
  return price || 0;
};

var _default = getShipmentFee;
exports["default"] = _default;