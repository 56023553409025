"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _Currency = _interopRequireDefault(require("@meepshop/context/lib/Currency"));

var _locales = require("@meepshop/locales");

// typescript import
// import
// definition
var _default = function _default(adTracks, fbq) {
  var _useContext = (0, _react.useContext)(_Currency["default"]),
      currency = _useContext.currency;

  var getLanguage = (0, _locales.useGetLanguage)();
  return (0, _react.useCallback)(function (_ref) {
    var id = _ref.id,
        title = _ref.title,
        price = _ref.price,
        sku = _ref.sku;
    if (!adTracks) return;
    var googleAnalyticsId = adTracks.googleAnalyticsId;
    fbq('track', 'ViewContent', {
      content_ids: [id],
      content_type: 'product',
      content_name: getLanguage(title),
      value: price,
      currency: currency
    });
    if (window.gtag && googleAnalyticsId) window.gtag('event', 'view_item', {
      items: [{
        id: id,
        name: getLanguage(title),
        price: price,
        sku: sku
      }]
    });
  }, [adTracks, fbq, currency, getLanguage]);
};

exports["default"] = _default;