"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "I18n", {
  enumerable: true,
  get: function get() {
    return _nextI18next.I18n;
  }
});
Object.defineProperty(exports, "languageType", {
  enumerable: true,
  get: function get() {
    return _constants.languageType;
  }
});
Object.defineProperty(exports, "I18nPropsType", {
  enumerable: true,
  get: function get() {
    return _constants.I18nPropsType;
  }
});
Object.defineProperty(exports, "languages", {
  enumerable: true,
  get: function get() {
    return _constants.languages;
  }
});
Object.defineProperty(exports, "withTranslation", {
  enumerable: true,
  get: function get() {
    return _i18n.withTranslation;
  }
});
Object.defineProperty(exports, "appWithTranslation", {
  enumerable: true,
  get: function get() {
    return _i18n.appWithTranslation;
  }
});
Object.defineProperty(exports, "i18n", {
  enumerable: true,
  get: function get() {
    return _i18n.i18n;
  }
});
Object.defineProperty(exports, "useTranslation", {
  enumerable: true,
  get: function get() {
    return _i18n.useTranslation;
  }
});
Object.defineProperty(exports, "Trans", {
  enumerable: true,
  get: function get() {
    return _i18n.Trans;
  }
});
Object.defineProperty(exports, "useGetLanguage", {
  enumerable: true,
  get: function get() {
    return _useGetLanguage["default"];
  }
});

var _nextI18next = require("next-i18next");

var _constants = require("./constants");

var _i18n = require("./i18n");

var _useGetLanguage = _interopRequireDefault(require("./hooks/useGetLanguage"));