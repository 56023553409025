"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.array.slice.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Trans = exports.useTranslation = exports.i18n = exports.appWithTranslation = exports.withTranslation = void 0;

var _nextI18next = _interopRequireDefault(require("next-i18next"));

var _path = _interopRequireDefault(require("path"));

var _constants = require("./constants");

var _config = _interopRequireDefault(require("next/config"));

/* eslint-disable no-nested-ternary */
// import
var _getConfig = (0, _config["default"])(),
    ENV = _getConfig.publicRuntimeConfig.ENV;

var isAdmin = function isAdmin() {
  return ['admin.stage.meepcloud.com', 'admin.meepshop.tw'].includes(window.location.hostname);
};
/*    
  Store (CDN)
      Server side
        All: /src/public/locales
      Client side
        Development: path.resolve('locales')
        Stage: https://cdn.meepstage.com/locales
        Production: https://cdn.meepshop.com/locales 
  Admin (無 CDN)
      Server side
        All: /src/public/locales
      Client side
        All: path.resolve('locales')
*/


var getLocalePath = function getLocalePath() {
  if (typeof window === 'undefined') {
    return _path["default"].resolve('src/public/locales');
  }

  if (isAdmin() || process.env.NODE_ENV === 'development') {
    return _path["default"].resolve('locales');
  }

  return ENV === 'production' ? 'https://cdn.meepshop.com/locales' : 'https://cdn.meepstage.com/locales';
}; // definition


var nextI18next = new _nextI18next["default"]({
  defaultLanguage: _constants.languages[0],
  otherLanguages: _constants.languages.slice(1),
  localePath: getLocalePath(),
  // we use 'null' to fall back
  fallbackLng: _constants.languages[0],
  serverLanguageDetection: false,
  returnNull: false
});
var withTranslation = nextI18next.withTranslation,
    appWithTranslation = nextI18next.appWithTranslation,
    i18n = nextI18next.i18n,
    useTranslation = nextI18next.useTranslation,
    Trans = nextI18next.Trans;
exports.Trans = Trans;
exports.useTranslation = useTranslation;
exports.i18n = i18n;
exports.appWithTranslation = appWithTranslation;
exports.withTranslation = withTranslation;