"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFormatFragment = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _client = require("@apollo/client");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment useFormatFragment on Store {\n    id\n    currency\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

// definition
var useFormatFragment = (0, _client.gql)(_templateObject());
exports.useFormatFragment = useFormatFragment;