"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.promise.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _dynamic = _interopRequireDefault(require("next/dynamic"));

var _default = (0, _dynamic["default"])(function () {
  return import(
  /* webpackChunkName: "MeepUiLandingPage" */
  "./LandingPage");
}, {
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak("./LandingPage")];
    },
    modules: ["../../../meep-ui/lib/landingPage/index.js -> " + "./LandingPage"]
  }
});

exports["default"] = _default;