"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMenu = exports.handleModuleDataMenuFragment = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _client = require("@apollo/client");

var _locale = require("@meepshop/utils/lib/gqls/locale");

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  query getMenu($menuId: String) {\n    viewer {\n      id\n      store {\n        id\n        menu(id: $menuId) {\n          id\n          ...handleModuleDataMenuFragment\n        }\n      }\n    }\n  }\n\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment handleModuleDataMenuFragment on Menu {\n    id\n    title {\n      ...localeFragment\n    }\n    notBeDeleted\n    menuType\n    iconSize\n    logoAlignment\n    pages {\n      id\n      pages {\n        id\n        pages {\n          id\n          ...handleModuleDataMenuPageObjectTypeFragment\n        }\n        ...handleModuleDataMenuPageObjectTypeFragment\n      }\n      ...handleModuleDataMenuPageObjectTypeFragment\n    }\n    design {\n      pattern\n      width\n      height\n      opacity\n      font\n      fontSize\n      showLogo\n      showTitle\n      showSearchbar\n      alignment\n      paddingTop\n      expandSubItem\n      normal: nornal {\n        color\n        background\n        borderColor\n      }\n      normalMobile {\n        color\n        background\n      }\n      hover {\n        color\n        background\n        borderColor\n      }\n      active {\n        color\n        background\n        borderColor\n      }\n    }\n    status\n  }\n\n  ", "\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment handleModuleDataMenuPageObjectTypeFragment on MenuPageObjectType {\n    id\n    title {\n      ...localeFragment\n    }\n    action\n    params {\n      path\n      pageId\n      url\n      section\n      query_string\n      tags\n      sort {\n        field\n        order\n      }\n      price {\n        lte\n        gte\n      }\n      from\n      size\n      displayMemberGroup\n      includedAllTags\n    }\n    image {\n      __typename\n      ... on Image {\n        id\n        scaledSrc {\n          w60\n          w120\n          w240\n        }\n      }\n\n      ... on DefaultIcon {\n        icon\n      }\n    }\n    imagePosition\n    newWindow\n  }\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var handleModuleDataMenuPageObjectTypeFragment = (0, _client.gql)(_templateObject(), _locale.localeFragment);
var handleModuleDataMenuFragment = (0, _client.gql)(_templateObject2(), handleModuleDataMenuPageObjectTypeFragment, _locale.localeFragment);
exports.handleModuleDataMenuFragment = handleModuleDataMenuFragment;
var getMenu = (0, _client.gql)(_templateObject3(), handleModuleDataMenuFragment);
exports.getMenu = getMenu;