import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.some.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.string.includes.js";
import { useEffect, useRef } from 'react';
import usePrevious from "react-use/lib/usePrevious";
import { useQuery } from '@apollo/client';
import getUnixTime from "date-fns/getUnixTime";
import addDays from "date-fns/addDays";
import notification from "antd/lib/notification";
import { useRouter } from '@meepshop/link';
import { useTranslation } from '@meepshop/locales';
import { getExpiringPoints } from "../gqls/useExpiringPoints"; // definition

var useExpiringPoints = function useExpiringPoints() {
  var _data$viewer, _data$viewer2, _data$viewer2$rewardP, _data$viewer2$rewardP2;

  var _useTranslation = useTranslation('ducks'),
      t = _useTranslation.t;

  var router = useRouter();
  var expireByTimeRef = useRef(getUnixTime(addDays(new Date(), 30)));
  var isSkipQuery = ['pay-now'].some(function (keyword) {
    return router === null || router === void 0 ? void 0 : router.route.includes(keyword);
  });

  var _useQuery = useQuery(getExpiringPoints, {
    ssr: false,
    variables: {
      expireBy: expireByTimeRef.current
    },
    skip: isSkipQuery
  }),
      data = _useQuery.data;

  var role = data === null || data === void 0 ? void 0 : (_data$viewer = data.viewer) === null || _data$viewer === void 0 ? void 0 : _data$viewer.role;
  var expiringPointsTotal = (data === null || data === void 0 ? void 0 : (_data$viewer2 = data.viewer) === null || _data$viewer2 === void 0 ? void 0 : (_data$viewer2$rewardP = _data$viewer2.rewardPoint) === null || _data$viewer2$rewardP === void 0 ? void 0 : (_data$viewer2$rewardP2 = _data$viewer2$rewardP.expiringPoints) === null || _data$viewer2$rewardP2 === void 0 ? void 0 : _data$viewer2$rewardP2.total) || 0;
  var prevRole = usePrevious(role);
  useEffect(function () {
    if (role && prevRole && role !== prevRole && role === 'SHOPPER' && expiringPointsTotal > 0) notification.info({
      message: t('expired-points-message'),
      description: t('expired-points-description', {
        point: expiringPointsTotal
      })
    });
  }, [t, role, prevRole, expiringPointsTotal]);
  return {};
};

export default useExpiringPoints;