"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getStoreCurrency = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _client = require("@apollo/client");

var _useInitFx = require("./useInitFx");

var _useFormat = require("./useFormat");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  query getStoreCurrency {\n    viewer {\n      id\n      store {\n        id\n        ...useFormatFragment\n      }\n    }\n\n    exchangeRateService {\n      ...useInitFxFragment\n    }\n  }\n\n  ", "\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

// definition
var getStoreCurrency = (0, _client.gql)(_templateObject(), _useFormat.useFormatFragment, _useInitFx.useInitFxFragment);
exports.getStoreCurrency = getStoreCurrency;