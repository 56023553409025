"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.object.assign.js");

require("core-js/modules/es.object.keys.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.includes.js");

require("core-js/modules/es.string.replace.js");

require("core-js/modules/web.dom-collections.for-each.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseRawContent = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _constants = require("../constants");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// definition
var handleInlineStyleRanges = function handleInlineStyleRanges(inlineStyleRanges) {
  return inlineStyleRanges.map(function (_ref) {
    var style = _ref.style,
        inline = (0, _objectWithoutProperties2["default"])(_ref, ["style"]);
    if (Object.keys(_constants.COLORS).includes(style) || /^#[\d\w]{6}$/.test(style)) return _objectSpread(_objectSpread({}, inline), {}, {
      style: "COLOR-".concat(_constants.COLORS[style] || style)
    });
    if (/^background-/.test(style)) return _objectSpread(_objectSpread({}, inline), {}, {
      style: "BGCOLOR-".concat(_constants.COLORS[style.replace(/^background-/, '')])
    });
    if (_constants.FONTFAMILY.map(function (_ref2) {
      var name = _ref2.name;
      return name;
    }).includes(style)) return _objectSpread(_objectSpread({}, inline), {}, {
      style: "FONTFAMILY-".concat(style)
    });
    return _objectSpread(_objectSpread({}, inline), {}, {
      style: style.toUpperCase().replace('#', '')
    });
  });
};

var handleDataType = function handleDataType(type, data) {
  if (/^align-.*$/.test(type)) {
    return {
      type: 'unstyled',
      data: _objectSpread(_objectSpread({}, data), {}, {
        textAlign: type.replace(/^align-/, '')
      })
    };
  }

  var _ref3 = data || {},
      textAlign = _ref3['text-align'],
      rest = (0, _objectWithoutProperties2["default"])(_ref3, ["text-align"]);

  if (textAlign) {
    return {
      type: type,
      data: _objectSpread(_objectSpread({}, rest), {}, {
        textAlign: textAlign
      })
    };
  }

  return {
    type: type,
    data: data
  };
};

var parseRawContent = function parseRawContent(value) {
  if (!value || !/entityMap/.test(value)) return null;
  var rawContent = JSON.parse(value); // format blocks

  rawContent.blocks = rawContent.blocks.map(function (_ref4) {
    var type = _ref4.type,
        data = _ref4.data,
        inlineStyleRanges = _ref4.inlineStyleRanges,
        block = (0, _objectWithoutProperties2["default"])(_ref4, ["type", "data", "inlineStyleRanges"]);
    return _objectSpread(_objectSpread(_objectSpread({}, block), handleDataType(type, data)), {}, {
      inlineStyleRanges: handleInlineStyleRanges(inlineStyleRanges)
    });
  }); // format entityMap

  Object.keys(rawContent.entityMap).forEach(function (key) {
    if (rawContent.entityMap[key].type === 'link') {
      var entity = Object.assign({}, rawContent.entityMap[key]);
      rawContent.entityMap[key] = _objectSpread(_objectSpread({}, entity), {}, {
        type: 'LINK'
      });
    }

    if (rawContent.entityMap[key].type === 'LINK') {
      var _rawContent$entityMap = rawContent.entityMap[key],
          _rawContent$entityMap2 = _rawContent$entityMap.data,
          url = _rawContent$entityMap2.url,
          href = _rawContent$entityMap2.href,
          targetOption = _rawContent$entityMap2.targetOption,
          target = _rawContent$entityMap2.target,
          data = (0, _objectWithoutProperties2["default"])(_rawContent$entityMap2, ["url", "href", "targetOption", "target"]),
          _entity = (0, _objectWithoutProperties2["default"])(_rawContent$entityMap, ["data"]);

      rawContent.entityMap[key] = _objectSpread(_objectSpread({}, _entity), {}, {
        data: _objectSpread(_objectSpread({}, data), {}, {
          href: url || href,
          target: targetOption || target
        })
      });
    }
  });
  return rawContent;
};

exports.parseRawContent = parseRawContent;