"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hideSidebarInPhone = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _media = require("../../constants/media");

var hideSidebarInPhone = (0, _defineProperty2["default"])({}, _media.PHONE_MEDIA, {
  display: 'none'
});
exports.hideSidebarInPhone = hideSidebarInPhone;