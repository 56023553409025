"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.array.reduce.js");

require("core-js/modules/es.object.keys.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// typescript import
// definition
var _default = function _default(prevResolvers, newResolvers) {
  return Object.keys(newResolvers).reduce(function (result, key) {
    return _objectSpread(_objectSpread({}, result), {}, (0, _defineProperty2["default"])({}, key, Object.keys(newResolvers[key]).reduce(function (fieldResult, field) {
      return _objectSpread(_objectSpread({}, fieldResult), {}, (0, _defineProperty2["default"])({}, field, !(fieldResult !== null && fieldResult !== void 0 && fieldResult[field]) ? newResolvers[key][field] : function () {
        var _newResolvers$key;

        var prevData = fieldResult[field].apply(fieldResult, arguments);

        var newData = (_newResolvers$key = newResolvers[key])[field].apply(_newResolvers$key, arguments);

        if (!prevData || !newData) return null;
        if (prevData instanceof Array && newData instanceof Array) return (prevData.length > newData.length ? prevData : newData).map(function (_, index) {
          return _objectSpread(_objectSpread({}, prevData[index]), newData[index]);
        });
        return _objectSpread(_objectSpread({}, prevData), newData);
      }));
    }, result[key])));
  }, prevResolvers);
};

exports["default"] = _default;