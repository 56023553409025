"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _pino = _interopRequireDefault(require("pino"));

var _config = _interopRequireDefault(require("next/config"));

var _getConfig;

// definition
// FIXME: remove getconfig after removing express
var LOG_LEVEL = ((_getConfig = (0, _config["default"])()) === null || _getConfig === void 0 ? void 0 : _getConfig.publicRuntimeConfig.LOG_LEVEL) || process.env.LOG_LEVEL || 'info';

var _default = (0, _pino["default"])({
  level: LOG_LEVEL,
  formatters: {
    level: function level(label) {
      return {
        severity: {
          trace: 'DEBUG',
          debug: 'DEBUG',
          info: 'INFO',
          warn: 'WARNING',
          error: 'ERROR',
          fatal: 'CRITICAL'
        }[label]
      };
    }
  },
  serializers: {
    err: _pino["default"].stdSerializers.err,
    error: _pino["default"].stdSerializers.err
  }
});

exports["default"] = _default;