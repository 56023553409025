"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.date.to-string.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _format = _interopRequireDefault(require("date-fns/format"));

var _isValid = _interopRequireDefault(require("date-fns/isValid"));

// import
// definition
var _default = function _default(date, dateFormat) {
  if (!date || !(0, _isValid["default"])(new Date(date))) return null;
  return (0, _format["default"])(new Date(date), dateFormat || 'yyyy/MM/dd');
};

exports["default"] = _default;