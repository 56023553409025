"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

/* 判斷湊湊團購物流程是否符合免運標準 */
var isGroupBuyFreeShipping = function isGroupBuyFreeShipping(condition, shipmentFee, quantity) {
  return typeof condition === 'number' && typeof shipmentFee === 'number' && condition > 0 && // condition 0 = 沒有開啟免運功能
  quantity >= condition && Math.abs(shipmentFee) > 0;
}; // 目前運費可能為負數，需要變成絕對值


var _default = isGroupBuyFreeShipping;
exports["default"] = _default;