"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.array.reduce.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.string.link.js");

require("core-js/modules/web.dom-collections.for-each.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.useApolloNetworkStatus = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _v = _interopRequireDefault(require("uuid/v4"));

var _client = require("@apollo/client");

var _context = require("@apollo/client/link/context");

var _retry = require("@apollo/client/link/retry");

var _reactApolloNetworkStatus = require("react-apollo-network-status");

var _logger = _interopRequireDefault(require("@meepshop/logger"));

var _cacheConfig = _interopRequireDefault(require("../../cacheConfig"));

var getDraftText = _interopRequireWildcard(require("../clientSideSchema/getDraftText"));

var guestCart = _interopRequireWildcard(require("../clientSideSchema/guestCart"));

var landingPageAccessToken = _interopRequireWildcard(require("../clientSideSchema/landingPageAccessToken"));

var log = _interopRequireWildcard(require("../clientSideSchema/log"));

var login = _interopRequireWildcard(require("../clientSideSchema/login"));

var logout = _interopRequireWildcard(require("../clientSideSchema/logout"));

var PageInfo = _interopRequireWildcard(require("../clientSideSchema/PageInfo"));

var settingObjectType = _interopRequireWildcard(require("../clientSideSchema/settingObjectType"));

var upsertGuestCart = _interopRequireWildcard(require("../clientSideSchema/upsertGuestCart"));

var validatedConvenienceStoreCities = _interopRequireWildcard(require("../clientSideSchema/validatedConvenienceStoreCities"));

var _mergeResolvers = _interopRequireDefault(require("./mergeResolvers"));

var _errorLink = _interopRequireDefault(require("./errorLink"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// definition
var _createNetworkStatusN = (0, _reactApolloNetworkStatus.createNetworkStatusNotifier)(),
    link = _createNetworkStatusN.link,
    useApolloNetworkStatus = _createNetworkStatusN.useApolloNetworkStatus;

exports.useApolloNetworkStatus = useApolloNetworkStatus;
var apolloClient = null;

var create = function create(_ref, initialState, ctx) {
  var name = _ref.name,
      terminatingLink = _ref.terminatingLink,
      _ref$initializeCache = _ref.initializeCache,
      initializeCache = _ref$initializeCache === void 0 ? [] : _ref$initializeCache,
      _ref$resolvers = _ref.resolvers,
      resolvers = _ref$resolvers === void 0 ? [] : _ref$resolvers,
      _ref$errorFilter = _ref.errorFilter,
      errorFilter = _ref$errorFilter === void 0 ? Boolean : _ref$errorFilter,
      loggerInfo = _ref.loggerInfo;
  var cache = new _client.InMemoryCache(_objectSpread(_objectSpread({}, _cacheConfig["default"]), {}, {
    typePolicies: _objectSpread(_objectSpread({}, _cacheConfig["default"].typePolicies), {}, {
      Order: {
        fields: {
          partialShipments: {
            merge: function merge(_, incoming) {
              return incoming; // 這邊是為了刪除部分出貨紀錄設置的，為了解決 Cache data may be lost 所做的 safely merge
            }
          }
        }
      }
    }),
    dataIdFromObject: function dataIdFromObject(data) {
      var id = data.id,
          __typename = data.__typename;
      if (['GalleryImage', 'OrderProductAvailableForApply'].includes(__typename)) return "".concat(__typename, ":").concat(id);
      return id || (0, _client.defaultDataIdFromObject)(data);
    }
  })).restore(initialState || {});
  var logger = (0, _logger["default"])(loggerInfo);
  if (!initialState) initializeCache.forEach(function (initialize) {
    return initialize(cache, ctx);
  });
  return new _client.ApolloClient({
    name: name,
    version: "".concat(process.env.NEXT_PUBLIC_VERSION, "-").concat(typeof window === 'undefined' ? 'server' : 'browser'),
    connectToDevTools: typeof window !== 'undefined',
    ssrMode: typeof window === 'undefined',
    cache: cache,
    resolvers: [].concat((0, _toConsumableArray2["default"])(resolvers), [landingPageAccessToken.resolvers, log.resolvers(logger), login.resolvers, logout.resolvers, PageInfo.resolvers, upsertGuestCart.resolvers, settingObjectType.resolvers(logger), validatedConvenienceStoreCities.resolvers, getDraftText.resolvers(logger), guestCart.resolvers]).reduce(_mergeResolvers["default"], {}),
    link: (0, _client.from)([new _retry.RetryLink({
      delay: {
        initial: 500,
        max: Infinity,
        jitter: true
      },
      attempts: {
        max: 10,
        retryIf: function retryIf(error) {
          logger.error("[FE] - ".concat(ctx ? ctx === null || ctx === void 0 ? void 0 : ctx.ctx.req.headers.host : '', " - ").concat((loggerInfo === null || loggerInfo === void 0 ? void 0 : loggerInfo.identity) || (0, _v["default"])(), " - RETRY_LINK EXECUTED \u26A0\uFE0F - ").concat(error));
          return Boolean(error) && ![401, 403].includes(error.statusCode);
        }
      }
    }), (0, _errorLink["default"])(errorFilter, logger), link, (0, _context.setContext)(function () {
      var _ctx$ctx$req$cookies;

      return {
        uri: // server 端可以直 call ， client 端則是為了不 expose 後端 API 到 browser 所做的 proxy
        typeof window !== 'undefined' ? '/api/graphql' : "".concat(process.env.MEEPSHOP_API, "/graphql"),
        credentials: 'include',
        headers: _objectSpread(_objectSpread({}, !ctx ? {} : {
          'x-meepshop-domain': ctx.ctx.req.headers.host,
          'x-meepshop-authorization-token': ((_ctx$ctx$req$cookies = ctx.ctx.req.cookies) === null || _ctx$ctx$req$cookies === void 0 ? void 0 : _ctx$ctx$req$cookies['x-meepshop-authorization-token']) || ''
        }), {}, {
          'x-meepshop-unique-id': (loggerInfo === null || loggerInfo === void 0 ? void 0 : loggerInfo.identity) || ''
        })
      };
    }), terminatingLink])
  });
};

var _default = function _default() {
  if (typeof window === 'undefined') return create.apply(void 0, arguments);
  if (!apolloClient) apolloClient = create.apply(void 0, arguments);
  return apolloClient;
};

exports["default"] = _default;