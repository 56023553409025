"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resolvers = void 0;

var _pageInfo = require("../gqls/pageInfo");

// typescript import
// graphql typescript

/* TODO: should use @meepshop/types/gqls/meepshop */
// graphql import
// definition
var resolvers = {
  Mutation: {
    setCurrent: function setCurrent(_, _ref, _ref2) {
      var _ref$input = _ref.input,
          pageId = _ref$input.pageId,
          current = _ref$input.current;
      var cache = _ref2.cache;
      cache.writeFragment({
        id: pageId,
        fragment: _pageInfo.pageInfoFragment,
        data: {
          __typename: 'CurrentInfo',
          id: pageId,
          current: current
        }
      });
      return true;
    }
  },
  PageInfo: {
    currentInfo: function currentInfo(_ref3, args) {
      var _args$input;

      var key = _ref3.key;
      return {
        __typename: 'CurrentInfo',
        id: key || (args === null || args === void 0 ? void 0 : (_args$input = args.input) === null || _args$input === void 0 ? void 0 : _args$input.pageId),
        current: 0
      };
    }
  }
};
exports.resolvers = resolvers;