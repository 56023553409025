"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.date.to-string.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _isAfter = _interopRequireDefault(require("date-fns/isAfter"));

var _isValid = _interopRequireDefault(require("date-fns/isValid"));

var canRepay = function canRepay(_ref) {
  var paymentFlowType = _ref.paymentFlowType,
      orderStatus = _ref.orderStatus,
      paymentStatus = _ref.paymentStatus,
      expiredTime = _ref.expiredTime;
  return paymentFlowType === 'Immediate' && // 即時金流才有重新付款功能
  typeof orderStatus === 'number' && typeof paymentStatus === 'number' && orderStatus === 0 && // 訂單狀態 0 (處理中)
  paymentStatus === 0 && // 付款狀態 0 (等待確認)
  !!expiredTime && // 有付款過期時間 (expiredTime) 才可顯示，第一次付款失敗後才會出現 expiredTime
  (0, _isValid["default"])(new Date(expiredTime)) && (0, _isAfter["default"])(new Date(expiredTime), new Date());
}; // 判斷沒有過期


var _default = canRepay;
exports["default"] = _default;