"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _money = _interopRequireDefault(require("money"));

var _Currency = require("@meepshop/context/lib/Currency");

// typescript import
// import
// definition
var _default = function _default(customerCurrency, store) {
  return (0, _react.useCallback)(function (price, noSymbol, noCountrySymbol, noSpace) {
    var storeCurrency = (store === null || store === void 0 ? void 0 : store.currency) || 'TWD';
    return (0, _Currency.format)(customerCurrency, (0, _money["default"])(price).from(storeCurrency).to(customerCurrency), noSymbol, noCountrySymbol, noSpace);
  }, [customerCurrency, store]);
};

exports["default"] = _default;