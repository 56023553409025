"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.promise.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "handleModuleData", {
  enumerable: true,
  get: function get() {
    return _handleModuleData["default"];
  }
});
exports["default"] = void 0;

var _dynamic = _interopRequireDefault(require("next/dynamic"));

var _handleModuleData = _interopRequireDefault(require("./utils/handleModuleData"));

var _default = (0, _dynamic["default"])(function () {
  return import(
  /* webpackChunkName: "MeepUiMenu" */
  "./Menu");
}, {
  loadableGenerated: {
    webpack: function webpack() {
      return [require.resolveWeak("./Menu")];
    },
    modules: ["../../../meep-ui/lib/menu/index.js -> " + "./Menu"]
  }
});

exports["default"] = _default;