"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.date.to-string.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateTime = void 0;

var _setHours = _interopRequireDefault(require("date-fns/setHours"));

var _setSeconds = _interopRequireDefault(require("date-fns/setSeconds"));

var _setMinutes = _interopRequireDefault(require("date-fns/setMinutes"));

var _isValid = _interopRequireDefault(require("date-fns/isValid"));

var updateTime = function updateTime(_ref) {
  var dateTime = _ref.dateTime,
      hh = _ref.hh,
      mm = _ref.mm,
      ss = _ref.ss;
  if (!dateTime || !(0, _isValid["default"])(new Date(dateTime))) return new Date();
  var date = typeof dateTime === 'string' ? new Date(dateTime) : dateTime;

  if (typeof hh === 'number') {
    date = (0, _setHours["default"])(date, hh);
  }

  if (typeof mm === 'number') {
    date = (0, _setMinutes["default"])(date, mm);
  }

  if (typeof ss === 'number') {
    date = (0, _setSeconds["default"])(date, ss);
  }

  return date;
};

exports.updateTime = updateTime;