"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resolvers = void 0;
// definition
var resolvers = {
  User: {
    guestCart: function guestCart() {
      return typeof window === 'undefined' ? {
        __typename: 'ServerGuestCart'
      } : {
        __typename: 'GuestCart',
        cartItems: JSON.parse(localStorage.getItem('guestCart') || '[]')
      };
    }
  }
};
exports.resolvers = resolvers;