"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.promise.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = require("react");

var _v = _interopRequireDefault(require("uuid/v4"));

var _config = _interopRequireDefault(require("next/config"));

var _cookies = _interopRequireDefault(require("@meepshop/cookies"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// definition
var _getConfig = (0, _config["default"])(),
    ENV = _getConfig.publicRuntimeConfig.ENV;

var _default = function _default(_ref) {
  var store = _ref.store,
      _ref$email = _ref.email,
      email = _ref$email === void 0 ? '' : _ref$email;

  var _useContext = (0, _react.useContext)(_cookies["default"]),
      cookies = _useContext.cookies;

  return (0, _react.useCallback)(function (eventType, eventName, options) {
    if (!store) return;
    var _store$adTracks = store.adTracks,
        facebookPixelId = _store$adTracks.facebookPixelId,
        facebookConversionsAccessToken = _store$adTracks.facebookConversionsAccessToken;
    if (typeof window === 'undefined' || !window.fbq || !facebookPixelId) return;
    var eventId = (0, _v["default"])();
    window.fbq(eventType, eventName, options, {
      eventID: eventId
    });

    if (facebookConversionsAccessToken) {
      var domain = ENV === 'stage' ? 'fb-conversions-proxy.meepstage.com' : 'fb-conversions-proxy.meepshop.com';
      fetch("https://".concat(domain, "/send-to-conversions-api"), {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'same-origin',
        body: JSON.stringify(_objectSpread(_objectSpread({}, options), {}, {
          event_id: eventId,
          event_name: eventName,
          event_source_url: window.location.href,
          store_id: store.id,
          external_id: cookies.identity
        }, email ? {
          em: email
        } : {}))
      });
    }
  }, [store, cookies.identity, email]);
};

exports["default"] = _default;