"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.array.reduce.js");

require("core-js/modules/es.date.to-string.js");

require("core-js/modules/es.object.keys.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.regexp.to-string.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.buildD3TreeTypes = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _isInt = _interopRequireDefault(require("validator/lib/isInt"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var buildPropTypes = function buildPropTypes(type, validator) {
  var valueType = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'string';

  var checkPropType = function checkPropType(isRequired) {
    return function (prevProps, propName, componentName) {
      var _prevProps$propName;

      var value = (_prevProps$propName = prevProps[propName]) === null || _prevProps$propName === void 0 ? void 0 : _prevProps$propName.toString();

      if (value && (!validator(value, prevProps, propName, componentName) || // eslint-disable-next-line  valid-typeof
      (0, _typeof2["default"])(prevProps[propName]) !== valueType)) {
        return new Error("Invalid prop `".concat(propName, "` of type `").concat(type, "` supplied to `").concat(componentName, "`, expected `").concat(type, "`"));
      }

      if (!value && isRequired) {
        return new Error("The prop `".concat(propName, "` is marked as required in `").concat(componentName, "`, but its value is `").concat(value, "`."));
      }

      return null;
    };
  };

  var outputFunc = checkPropType();
  outputFunc.isRequired = checkPropType(true);
  return outputFunc;
};

var buildD3TreeTypes = function buildD3TreeTypes(dataName, nodeType, dataType) {
  var checkD3TreeType = function checkD3TreeType(isRequired) {
    var depth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
    return function () {
      var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var propName = arguments.length > 1 ? arguments[1] : undefined;
      var componentName = arguments.length > 2 ? arguments[2] : undefined;
      var children = props.children,
          data = props.data;

      var propTypes = _objectSpread({
        id: _propTypes["default"].string,
        height: buildPropTypes('positive integer', function (value) {
          return (0, _isInt["default"])(value, {
            min: 0
          });
        }),
        depth: _propTypes["default"].oneOf([depth]),
        data: _propTypes["default"].shape(_objectSpread(_objectSpread(_objectSpread({
          key: _propTypes["default"].string.isRequired
        }, depth === 0 ? null : {
          parent: _propTypes["default"].string.isRequired
        }), depth === 0 || !children ? null : nodeType), depth === 0 || children ? null : (0, _defineProperty2["default"])({}, dataName, _propTypes["default"].checkPropTypes(dataType, (data || {})[dataName], "".concat(propName, ".data.").concat(dataName), componentName))))
      }, depth === 0 ? null : {
        parent: _propTypes["default"].shape({
          id: _propTypes["default"].string.isRequired
        })
      });

      var defaultPropTypes = !children ? {} : {
        children: _propTypes["default"].arrayOf(function (propValue, key) {
          return checkD3TreeType(isRequired, depth + 1)(propValue[key], "".concat(propName, ".children[").concat(key, "]"), componentName);
        }).isRequired
      };
      return _propTypes["default"].checkPropTypes(Object.keys(propTypes).reduce(function (result, key) {
        return _objectSpread(_objectSpread({}, result), {}, (0, _defineProperty2["default"])({}, key, depth > 0 || isRequired ? propTypes[key].isRequired : propTypes[key]));
      }, defaultPropTypes), props, propName, componentName);
    };
  };

  var checkPropTypeFunc = function checkPropTypeFunc(isRequired) {
    return function () {
      var prevProps = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var propName = arguments.length > 1 ? arguments[1] : undefined;
      var componentName = arguments.length > 2 ? arguments[2] : undefined;
      return checkD3TreeType(isRequired)(prevProps[propName], propName, componentName);
    };
  };

  var outputFunc = checkPropTypeFunc();
  outputFunc.isRequired = checkPropTypeFunc(true);
  return outputFunc;
};

exports.buildD3TreeTypes = buildD3TreeTypes;
var _default = buildPropTypes;
exports["default"] = _default;