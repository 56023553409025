"use strict";

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/web.dom-collections.for-each.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
var flattenWidgets = function flattenWidgets(widgets) {
  var result = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  widgets.forEach(function (widget) {
    if (widget.id && widget.module) {
      var id = widget.id;
      result.push({
        id: id
      });
    }

    if (widget.widgets) {
      flattenWidgets(widget.widgets, result);
    }
  });
  return result;
};

var _default = flattenWidgets;
exports["default"] = _default;