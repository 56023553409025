"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TABLET_MEDIA = exports.PHONE_MEDIA = exports.TABLET_MEDIA_WIDTH = exports.PHONE_MEDIA_WIDTH = void 0;
var PHONE_MEDIA_WIDTH = 768;
exports.PHONE_MEDIA_WIDTH = PHONE_MEDIA_WIDTH;
var TABLET_MEDIA_WIDTH = 992;
exports.TABLET_MEDIA_WIDTH = TABLET_MEDIA_WIDTH;
var PHONE_MEDIA = "@media (min-width: 1px) and (max-width: ".concat(PHONE_MEDIA_WIDTH - 1, "px)");
exports.PHONE_MEDIA = PHONE_MEDIA;
var TABLET_MEDIA = "@media (min-width: 1px) and (max-width: ".concat(TABLET_MEDIA_WIDTH - 1, "px)");
exports.TABLET_MEDIA = TABLET_MEDIA;