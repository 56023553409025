"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = require("react");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// definition
var _default = function _default(fbq) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      pageId = _ref.pageId,
      snapshotId = _ref.snapshotId;

  return (0, _react.useCallback)(function (action, name, category, customProperty) {
    var _ref2 = customProperty || {},
        eventElement = _ref2.eventElement,
        eventAction = _ref2.eventAction;

    fbq('trackCustom', name);
    if (typeof window !== 'undefined' && window.gtag) window.gtag('event', action, _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, !category ? {} : {
      event_category: category
    }), eventElement ? {
      event_element: eventElement
    } : {}), snapshotId ? {
      snapshot_id: snapshotId
    } : {}), pageId ? {
      page_id: pageId
    } : {}), eventAction ? {
      event_action: eventAction
    } : {}), {}, {
      event_label: name,
      non_interaction: true
    }));
  }, [fbq, pageId, snapshotId]);
};

exports["default"] = _default;