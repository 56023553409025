"use strict";

require("core-js/modules/es.array.map.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _locales = require("@meepshop/locales");

// typescript import
// import
// definition
var _default = function _default(adTracks, fbq) {
  var getLanguage = (0, _locales.useGetLanguage)();
  return (0, _react.useCallback)(function (_ref) {
    var searchString = _ref.searchString,
        products = _ref.products;
    if (!adTracks) return;
    var googleAnalyticsId = adTracks.googleAnalyticsId;
    fbq('track', 'Search', {
      search_string: searchString
    });
    if (window.gtag && googleAnalyticsId && products) window.gtag('event', 'view_item_list', {
      items: products.map(function (_ref2) {
        var id = _ref2.id,
            title = _ref2.title;
        return {
          id: id,
          name: getLanguage(title),
          list_name: searchString
        };
      })
    });
  }, [adTracks, fbq, getLanguage]);
};

exports["default"] = _default;