"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _locales = require("@meepshop/locales");

// import
// definition
var _default = function _default(code) {
  var _useTranslation = (0, _locales.useTranslation)('errorCode'),
      t = _useTranslation.t;

  var failConditionText;

  switch (code) {
    case 'FAIL_DUPLICATE_SPEC':
      failConditionText = t('repeat-specs');
      break;

    case 'FAIL_SPEC_ERROR':
      failConditionText = t('wrong-specs');
      break;

    case 'FAIL_LIST_PRICE_NOT_NUBMER':
      failConditionText = t('price-not-num');
      break;

    case 'FAIL_LIST_PRICE_LESS_THAN_ZERO':
      failConditionText = t('price-less-zero');
      break;

    case 'FAIL_RETAIL_PRICE_NOT_NUMBER':
      failConditionText = t('sales-not-num');
      break;

    case 'FAIL_RETAIL_PRICE_LESS_THAN_ZERO':
      failConditionText = t('sales-less-zero');
      break;

    case 'FAIL_STOCK_NOT_INTEGER':
      failConditionText = t('storage-not-num');
      break;

    case 'FAIL_STOCK_LESS_THAN_ZERO':
      failConditionText = t('storage-less-zero');
      break;

    case 'FAIL_COST_NOT_NUMBER':
      failConditionText = t('cost-not-num');
      break;

    case 'FAIL_COST_LESS_THAN_ZERO':
      failConditionText = t('cost-less-zero');
      break;

    case 'FAIL_MIN_PURCHASE_QTY_NOT_NUMBER':
      failConditionText = t('min-buying-not-num');
      break;

    case 'FAIL_MIN_PURCHASE_QTY_LESS_THAN_ZERO':
      failConditionText = t('min-buying-less-zero');
      break;

    case 'FAIL_MAX_PURCHASE_QTY_NOT_NUMBER':
      failConditionText = t('max-buying-not-num');
      break;

    case 'FAIL_MAX_PURCHASE_QTY_LESS_THAN_ZERO':
      failConditionText = t('max-buying-less-zero');
      break;

    case 'FAIL_SUGGESTED_PRICE_NOT_NUMBER':
      failConditionText = t('suggested-price-not-number');
      break;

    case 'FAIL_SUGGESTED_PRICE_LESS_THAN_ZERO':
      failConditionText = t('suggested-price-negative-number');
      break;

    case 'FAIL_EXCEEDING_QUANTITY_LIMIT':
      failConditionText = t('too-many-quantity');
      break;

    case 'FAIL_UNSUPPORTED_FILE_VERSION':
      failConditionText = t('use-new-version');
      break;

    case 'FAIL_UPLOAD_TO_DB_FAILED':
      failConditionText = t('add-product-fail');
      break;

    case 'FAIL_FILE_SIZE_TOO_LARGE':
      failConditionText = t('file-oversize');
      break;

    case 'FAIL_UNSUPPORTED_FILE_TYPE':
      failConditionText = t('wrong-file-type');
      break;

    case 'FAIL_PERMISSION_DENIED':
      failConditionText = t('no-autorization');
      break;

    case 'FAIL_FILE_NOT_FOUND':
      failConditionText = t('no-upload');
      break;

    case 'FAIL_TOO_MANY_DATA_ROWS':
      failConditionText = t('too-many-files');
      break;

    case 'FAIL_PRODUCT_TEMPLATE_PAGE_NOT_FOUND':
      failConditionText = t('no-any-template');
      break;

    case 'FAIL_INVALID_CONTENT_FORMAT':
      failConditionText = t('invalid-content-format');
      break;

    case 'FAIL_UNKNOWN_ERROR':
      failConditionText = t('unknown-error');
      break;

    default:
      failConditionText = t('add-product-fail');
      break;
  }

  return failConditionText;
};

exports["default"] = _default;