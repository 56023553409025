"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.array.find.js");

require("core-js/modules/es.array.join.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.array.reduce.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.string.starts-with.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _react = require("react");

var _locales = require("@meepshop/locales");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// definition
var _default = function _default(store, fbq) {
  var getLanguage = (0, _locales.useGetLanguage)();
  return (0, _react.useCallback)(function (_ref) {
    var orderNo = _ref.orderNo,
        products = _ref.products,
        user = _ref.user,
        total = _ref.total,
        currency = _ref.currency,
        shipmentFee = _ref.shipmentFee,
        paymentFee = _ref.paymentFee;
    if (!store) return;
    var name = user.name,
        email = user.email,
        mobile = user.mobile,
        userId = user.id;
    var value = total - shipmentFee - paymentFee;
    var cname = store.cname,
        _store$adTracks = store.adTracks,
        googleAnalyticsId = _store$adTracks.googleAnalyticsId,
        googleAdwordsConfig = _store$adTracks.googleAdwordsConfig,
        googleAdwordsPurchase = _store$adTracks.googleAdwordsPurchase;
    fbq('track', 'Purchase', {
      content_ids: products.map(function (_ref2) {
        var productId = _ref2.productId;
        return productId;
      }),
      content_type: 'product',
      value: total,
      currency: currency
    });
    if (window.gtag && googleAnalyticsId) window.gtag('event', 'purchase', {
      transaction_id: orderNo,
      affiliation: cname,
      value: value,
      currency: currency,
      shipping: shipmentFee,
      items: products === null || products === void 0 ? void 0 : products.filter(function (product) {
        return (product === null || product === void 0 ? void 0 : product.type) === 'product';
      }).reduce(function (result, _ref3) {
        var productId = _ref3.productId,
            title = _ref3.title,
            specs = _ref3.specs,
            totalPrice = _ref3.totalPrice,
            quantity = _ref3.quantity,
            sku = _ref3.sku;
        var product = result.find(function (_ref4) {
          var id = _ref4.id;
          return id === productId;
        });
        var oldGA = {
          id: productId,
          name: getLanguage(title),
          variant: specs === null || specs === void 0 ? void 0 : specs.map(function (spec) {
            return getLanguage(spec === null || spec === void 0 ? void 0 : spec.title);
          }).join('/')
        };
        var newGA4 = {
          item_id: productId,
          item_name: getLanguage(title),
          item_variant: specs === null || specs === void 0 ? void 0 : specs.map(function (spec) {
            return getLanguage(spec === null || spec === void 0 ? void 0 : spec.title);
          }).join('/')
        };
        if (!product) return [].concat((0, _toConsumableArray2["default"])(result), [_objectSpread(_objectSpread({}, !googleAnalyticsId.startsWith('UA-') ? newGA4 : oldGA), {}, {
          currency: currency,
          price: totalPrice / quantity,
          quantity: quantity,
          sku: sku
        })]); // FIXME: fix for 選選樂

        product.quantity += quantity || 0;
        return result;
      }, [])
    });

    if (window.gtag && googleAdwordsConfig && googleAdwordsPurchase) {
      // 因為訪客購買-未登入（先前未在 Head 設定），故需在此設定 user_data
      window.gtag('set', 'user_data', _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, userId ? {
        id: userId
      } : {}), name ? {
        name: name
      } : {}), email ? {
        email: email
      } : {}), mobile ? {
        phone_number: mobile
      } : {}));
      window.gtag('event', 'conversion', _objectSpread(_objectSpread({
        send_to: googleAdwordsPurchase,
        value: value,
        currency: currency,
        transaction_id: orderNo
      }, email ? {
        email: email
      } : {}), mobile ? {
        phone_number: mobile
      } : {}));
    }
  }, [store, fbq, getLanguage]);
};

exports["default"] = _default;