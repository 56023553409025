"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.promise.js");

require("core-js/modules/web.dom-collections.for-each.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _error = require("@apollo/client/link/error");

var _config = _interopRequireDefault(require("next/config"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _getConfig = (0, _config["default"])(),
    ENV = _getConfig.publicRuntimeConfig.ENV; // definition


var _default = function _default(errorFilter, logger) {
  return (0, _error.onError)(function (_ref) {
    var response = _ref.response,
        graphQLErrors = _ref.graphQLErrors,
        networkError = _ref.networkError,
        operationName = _ref.operation.operationName;
    var errorLog = typeof window === 'undefined' ? logger.error : function (_ref2) {
      var name = _ref2.name,
          message = (0, _objectWithoutProperties2["default"])(_ref2, ["name"]);
      var statusCode = networkError && 'statusCode' in networkError ? networkError === null || networkError === void 0 ? void 0 : networkError.statusCode : null; // 送 network error log 到 mezmo

      fetch(ENV === 'stage' ? 'https://meepshop-client-logger-stage-35pdskza5q-de.a.run.app' : 'https://meepshop-client-logger-prod-35pdskza5q-de.a.run.app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(_objectSpread({
          statusCode: statusCode,
          errorName: name
        }, message))
      });
    };

    if (graphQLErrors) {
      var errors = graphQLErrors.filter(errorFilter);

      if (response && errors.length === 0) {
        response.errors = undefined;
        return;
      }

      errors.forEach(function (_ref3) {
        var message = _ref3.message,
            locations = _ref3.locations,
            path = _ref3.path;
        errorLog({
          name: 'GraphQL',
          operationName: operationName,
          message: message,
          locations: locations,
          path: path
        });
      });
    }

    if (networkError && 'statusCode' in networkError && [401, 403].includes(networkError.statusCode)) {
      if (typeof window !== 'undefined') {
        window.sessionStorage.setItem('redirectUrl', window.location.href);
      }

      return;
    }

    errorLog({
      name: 'Network',
      operationName: operationName,
      networkError: networkError
    });
  });
};

exports["default"] = _default;