"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _graphqlAnywhere = require("graphql-anywhere");

var _memoizee = _interopRequireDefault(require("memoizee"));

// import
// definition
var _default = (0, _memoizee["default"])(_graphqlAnywhere.filter);

exports["default"] = _default;