"use strict";

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.replace.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _index = require("../index");

// typescript import
// import
// definition
var _default = function _default() {
  var _useTranslation = (0, _index.useTranslation)('common'),
      i18n = _useTranslation.i18n;

  return (0, _react.useCallback)(function (locale) {
    var _i18n$language;

    return (locale === null || locale === void 0 ? void 0 : locale[(_i18n$language = i18n.language) === null || _i18n$language === void 0 ? void 0 : _i18n$language.replace('-', '_')]) || (locale === null || locale === void 0 ? void 0 : locale.zh_TW) || '';
  }, [i18n]);
};

exports["default"] = _default;