"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resolvers = void 0;

var _parseRawContent = require("../utils/parseRawContent");

// typescript import
// import
// definition
var resolvers = function resolvers(logger) {
  return {
    Query: {
      getDraftText: function getDraftText(_, _ref) {
        var input = _ref.input;

        try {
          return (0, _parseRawContent.parseRawContent)(input);
        } catch (error) {
          // FIXME: remove after all draft-js is formatted
          logger.error({
            name: 'FORMAT_DRAFT_ERROR',
            input: input,
            error: error
          });
          return null;
        }
      }
    }
  };
};

exports.resolvers = resolvers;