import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query getExpiringPoints($expireBy: Int!) {\n    viewer {\n      id\n      role\n      rewardPoint {\n        expiringPoints(expireBy: $expireBy) {\n          total\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { gql } from '@apollo/client';
export var getExpiringPoints = gql(_templateObject());