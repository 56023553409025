"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.match.js");

require("core-js/modules/es.string.replace.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = require("react");

var _router = require("next/router");

var _Domain = _interopRequireDefault(require("../Domain"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// definition
var _default = function _default() {
  var _newRouter$asPath$mat;

  var router = (0, _router.useRouter)();

  var _useContext = (0, _react.useContext)(_Domain["default"]),
      previousUrl = _useContext.previousUrl,
      domain = _useContext.domain,
      serverRouter = _useContext.serverRouter; // FIXME: remove query, ashPath after next.js upgrade, we should use AppTree with getDataFromTree


  var newRouter = typeof window === 'undefined' ? serverRouter : router;
  return _objectSpread(_objectSpread({}, newRouter), {}, {
    domain: domain,
    hash: ((_newRouter$asPath$mat = newRouter.asPath.match(/(#[^?]*)/)) === null || _newRouter$asPath$mat === void 0 ? void 0 : _newRouter$asPath$mat[0].replace(/^#/, '')) || null,
    back: function back(url) {
      return newRouter.push(url || previousUrl);
    },
    previousUrl: previousUrl
  });
};

exports["default"] = _default;