"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _router = require("next/router");

// import
// definition
var _default = function _default() {
  var offsetCacheRef = (0, _react.useRef)({
    previousOffset: 0,
    currentOffset: null
  });

  var _useState = (0, _react.useState)('/'),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      routeCache = _useState2[0],
      setRouteCache = _useState2[1];

  var router = (0, _router.useRouter)();
  (0, _react.useEffect)(function () {
    var handleStart = function handleStart(url) {
      if (url !== (router === null || router === void 0 ? void 0 : router.asPath)) {
        setRouteCache((router === null || router === void 0 ? void 0 : router.asPath) || '/');
      }
      /* 除了 checkout 頁面，其他頁面進到 /cart 前都要記錄路徑 */


      if (url === '/cart' && router.asPath !== '/checkout') {
        sessionStorage.setItem('previousUrl', (router === null || router === void 0 ? void 0 : router.asPath) || '/');
      }

      offsetCacheRef.current.previousOffset = window.pageYOffset;
    };

    var handleComplete = function handleComplete() {
      if (offsetCacheRef.current.currentOffset !== null) {
        window.scrollTo(0, offsetCacheRef.current.currentOffset);
        offsetCacheRef.current.currentOffset = null;
      }
    };

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.beforePopState(function () {
      offsetCacheRef.current.currentOffset = offsetCacheRef.current.previousOffset;
      return true;
    });
    return function () {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
    };
  }, [routeCache, router]);
  return routeCache;
};

exports["default"] = _default;