"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.reduce.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _filter = _interopRequireDefault(require("@meepshop/utils/lib/filter"));

var _useMergeCart = _interopRequireDefault(require("./useMergeCart"));

var _useUpsertCart2 = _interopRequireDefault(require("./useUpsertCart"));

var _useMergeCart2 = require("./gqls/useMergeCart");

var _useUpsertCart3 = require("./gqls/useUpsertCart");

// import
// graphql import
// definition
var _default = function _default(viewer) {
  var _viewer$guestCart2;

  var isShopper = (viewer === null || viewer === void 0 ? void 0 : viewer.role) === 'SHOPPER';
  var mergeCart = (0, _useMergeCart["default"])();

  var _useMemo = (0, _react.useMemo)(function () {
    var _viewer$cart, _viewer$guestCart;

    var cart = (viewer === null || viewer === void 0 ? void 0 : (_viewer$cart = viewer.cart) === null || _viewer$cart === void 0 ? void 0 : _viewer$cart.__typename) !== 'Cart' ? [] : viewer.cart.cartItems;
    var guestCart = (viewer === null || viewer === void 0 ? void 0 : (_viewer$guestCart = viewer.guestCart) === null || _viewer$guestCart === void 0 ? void 0 : _viewer$guestCart.__typename) !== 'GuestCart' ? [] : viewer.guestCart.cartItems;
    return {
      cartItems: !isShopper ? (0, _filter["default"])(_useMergeCart2.useMergeCartFragment, guestCart) : (0, _filter["default"])(_useMergeCart2.useMergeCartFragment, guestCart).reduce(mergeCart, cart)
    };
  }, [isShopper, mergeCart, viewer]),
      cartItems = _useMemo.cartItems;

  var _useUpsertCart = (0, _useUpsertCart2["default"])((0, _filter["default"])(_useUpsertCart3.useUpsertCartUserFragment, viewer)),
      loading = _useUpsertCart.loading,
      upsertCart = _useUpsertCart.upsertCart;

  return {
    loading: (viewer === null || viewer === void 0 ? void 0 : (_viewer$guestCart2 = viewer.guestCart) === null || _viewer$guestCart2 === void 0 ? void 0 : _viewer$guestCart2.__typename) !== 'GuestCart' || loading,
    cartItems: cartItems,
    mergeCart: mergeCart,
    upsertCart: (0, _react.useCallback)(function (cartItem) {
      return upsertCart(cartItem instanceof Array ? cartItem : mergeCart(cartItems, cartItem));
    }, [cartItems, mergeCart, upsertCart])
  };
};

exports["default"] = _default;