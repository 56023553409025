"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.reduce.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.getServerSideLoggerInfo = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _v = _interopRequireDefault(require("uuid/v4"));

var _browser = _interopRequireDefault(require("./browser"));

var _server = _interopRequireDefault(require("./server"));

var _constants = require("./constants");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// definition
var logger = typeof window !== 'undefined' ? _browser["default"] : _server["default"];
var BROWSER_INIT_INFO = {
  id: 'can`t be used',
  host: 'can`t be used',
  userAgent: 'can`t be used',
  userIP: 'can`t be used',
  url: 'can`t be used',
  identity: 'can`t be used'
};

var getServerSideLoggerInfo = function getServerSideLoggerInfo(ctx) {
  return {
    id: (0, _v["default"])(),
    host: ctx.req.headers.host,
    userAgent: ctx.req.headers['user-agent'],
    userIP: ctx.req.headers['x-forwarded-for'],
    url: ctx.req.url,
    identity: ctx.req.cookies.identity || "[TEMP UUID]: ".concat((0, _v["default"])(), " - identity will be initialized on client , you will be able to view identity on next request")
  };
};

exports.getServerSideLoggerInfo = getServerSideLoggerInfo;

var _default = function _default() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : BROWSER_INIT_INFO,
      id = _ref.id,
      rest = (0, _objectWithoutProperties2["default"])(_ref, ["id"]);

  var info = typeof window !== 'undefined' ? {
    id: id
  } : _objectSpread(_objectSpread({}, rest), {}, {
    id: id
  });
  return _constants.LOG_TYPES.reduce(function (result, type) {
    return _objectSpread(_objectSpread({}, result), {}, (0, _defineProperty2["default"])({}, type, function (data) {
      if (typeof data === 'string') logger[type](_objectSpread(_objectSpread({}, info), {}, {
        message: data
      }));else if (data instanceof Error) logger[type](_objectSpread(_objectSpread({}, info), {}, {
        message: data.message,
        stack: data.stack
      }));else logger[type](_objectSpread(_objectSpread({}, info), data));
    }));
  }, {});
};

exports["default"] = _default;