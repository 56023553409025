"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.array.join.js");

require("core-js/modules/es.array.map.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _Currency = _interopRequireDefault(require("@meepshop/context/lib/Currency"));

var _locales = require("@meepshop/locales");

// typescript import
// import
// definition
var _default = function _default(adTracks, fbq) {
  var _useContext = (0, _react.useContext)(_Currency["default"]),
      currency = _useContext.currency;

  var getLanguage = (0, _locales.useGetLanguage)();
  return (0, _react.useCallback)(function (_ref) {
    var eventName = _ref.eventName,
        id = _ref.id,
        title = _ref.title,
        quantity = _ref.quantity,
        specs = _ref.specs,
        price = _ref.price,
        sku = _ref.sku;
    if (!adTracks) return;
    var googleAnalyticsId = adTracks.googleAnalyticsId;

    var _ref2 = function () {
      switch (eventName) {
        case 'ec-popup':
          return {
            event: 'trackCustom',
            type: 'AddToCart_PopUp',
            label: 'popup'
          };

        case 'upselling':
          return {
            event: 'trackCustom',
            type: 'AddToCart_AdditionalPurchase',
            label: 'AdditionalPurchase'
          };

        default:
          return {
            event: 'track',
            type: 'AddToCart',
            label: 'general'
          };
      }
    }(),
        event = _ref2.event,
        type = _ref2.type,
        label = _ref2.label;

    fbq(event, type, {
      content_ids: [id],
      content_type: 'product',
      value: price,
      currency: currency
    });

    if (window.gtag && googleAnalyticsId) {
      window.gtag('event', 'add_to_cart', {
        currency: currency,
        value: price,
        event_label: label,
        items: [{
          id: id,
          name: getLanguage(title),
          variant: !specs ? '' : specs.map(function (spec) {
            return getLanguage(spec === null || spec === void 0 ? void 0 : spec.title);
          }).filter(Boolean).join('/'),
          quantity: quantity,
          price: price,
          sku: sku
        }]
      });
    }
  }, [adTracks, fbq, currency, getLanguage]);
};

exports["default"] = _default;