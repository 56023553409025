"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.languages = void 0;
// typescript import
// typescript definition
// definition
var languages = ['zh_TW', 'en_US', 'ja_JP', 'vi_VN', 'fr_FR', 'es_ES', 'th_TH', 'id_ID'];
exports.languages = languages;