"use strict";

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.replace.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _default = function _default(alignment) {
  switch (alignment) {
    case 'right':
      return 'FLEX_END';

    case 'left':
      return 'FLEX_START';

    default:
      return alignment.toUpperCase().replace(/-/, '_');
  }
};

exports["default"] = _default;