"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.array.map.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BLOCKS_TYPE = exports.WIDGETSETTING_TYPE = exports.PADDING_TYPE = exports.COMPONENTWIDTH_TYPE = exports.WIDTH_TYPE = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _propTypes2 = require("../constants/propTypes");

var WIDTH_TYPE = _propTypes["default"].oneOf([].constructor.apply({}, new Array(10)).map(function (_, index) {
  return (index + 1) * 10;
}));

exports.WIDTH_TYPE = WIDTH_TYPE;

var COMPONENTWIDTH_TYPE = _propTypes["default"].oneOf([0, // not use RWD
120, 240, 300]);

exports.COMPONENTWIDTH_TYPE = COMPONENTWIDTH_TYPE;

var PADDING_TYPE = _propTypes["default"].oneOf([0, 5, 10, 20, 30, 40, 50]);

exports.PADDING_TYPE = PADDING_TYPE;

var WIDGETSETTING_TYPE = _propTypes["default"].shape({
  componentWidth: COMPONENTWIDTH_TYPE,
  padding: PADDING_TYPE.isRequired,
  level: _propTypes["default"].number.isRequired
});

exports.WIDGETSETTING_TYPE = WIDGETSETTING_TYPE;
var BLOCKS_TYPE = {
  isRequired: function isRequired(prevProps, propName, componentName) {
    var value = prevProps[propName];

    if (value && value.length === 0) {
      return new Error("The prop `".concat(propName, "` is marked as required in `").concat(componentName, "`, but its value is `[]`."));
    }

    return _propTypes["default"].checkPropTypes({
      blocks: _propTypes["default"].arrayOf(_propTypes["default"].shape({
        id: _propTypes2.ID_TYPE,
        width: WIDTH_TYPE,
        componentWidth: COMPONENTWIDTH_TYPE.isRequired,
        padding: PADDING_TYPE.isRequired
      }).isRequired).isRequired
    }, prevProps, 'blocks', componentName);
  }
};
exports.BLOCKS_TYPE = BLOCKS_TYPE;