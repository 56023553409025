"use strict";

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.array.join.js");

require("core-js/modules/es.array.map.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _locales = require("@meepshop/locales");

// typescript import
// import
// definition
var _default = function _default(adTracks, fbq) {
  var getLanguage = (0, _locales.useGetLanguage)();
  return (0, _react.useCallback)(function (_ref) {
    var id = _ref.id,
        title = _ref.title,
        quantity = _ref.quantity,
        specs = _ref.specs,
        price = _ref.price,
        sku = _ref.sku;
    if (!adTracks) return;
    var googleAnalyticsId = adTracks.googleAnalyticsId;
    fbq('track', 'AddToWishList');

    if (window.gtag && googleAnalyticsId) {
      window.gtag('event', 'add_to_wishlist', {
        items: [{
          id: id,
          sku: sku,
          name: getLanguage(title),
          variant: !specs ? '' : specs.map(function (spec) {
            return getLanguage(spec === null || spec === void 0 ? void 0 : spec.title);
          }).filter(Boolean).join('/'),
          quantity: quantity,
          price: price
        }]
      });
    }
  }, [adTracks, fbq, getLanguage]);
};

exports["default"] = _default;