import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.date.to-string.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.parse-int.js";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { useMemo } from 'react';
import usePrevious from "react-use/lib/usePrevious";
import { useQuery } from '@apollo/client';
import { useTranslation } from '@meepshop/locales';
import { useRouter } from '@meepshop/link';
import { getJoinedModule, modifyWidgetDataInClient, flattenWidgets } from '@meepshop/utils';
import { getPage } from "../gqls/usePage";

var usePage = function usePage() {
  var _data$viewer, _data$computeProductL, _data$computeProductL2, _store$groupBuyEvent, _store$description, _store$description2, _store$setting, _store$defaultHomePag, _store$customPage;

  var _useTranslation = useTranslation('common'),
      i18n = _useTranslation.i18n;

  var router = useRouter();

  var _useQuery = useQuery(getPage, {
    variables: {
      path: router.query.path || '',
      productSearch: !router.query.pId ? {
        // 其他頁面都要看到商品列表內的商品為 isDisplayOnProductPage
        filter: {
          and: [{
            type: 'exact',
            field: 'displayToggle.isDisplayOnProductPage',
            query: 'true'
          }]
        }
      } : {
        size: 1,
        from: 0,
        filter: {
          and: [{
            type: 'ids',
            ids: [router.query.pId]
          }, {
            type: 'exact',
            field: 'displayToggle.isDisplayOnProductPage',
            query: 'true'
          }]
        },
        sort: [{
          field: 'createdAt',
          order: 'desc'
        }],
        showVariants: true,
        showMainFile: true
      },
      groupBuyId: router.query.eventId || '',
      isHomePage: router.pathname === '/',
      isCustomPage: router.pathname === '/pages/[path]',
      // FIXME: remove /product/[pId] when product page gets removed
      isProductPage: ['/products/[pId]', '/product/[pId]'].includes(router.pathname),
      isProductsPage: router.pathname === '/products',
      isGroupBuyEventPage: router.pathname === '/events/group-buy/[eventId]'
    }
  }),
      originalData = _useQuery.data,
      loading = _useQuery.loading,
      client = _useQuery.client;

  var prevData = usePrevious(originalData);
  var data = loading && prevData ? prevData : originalData;
  var store = (data === null || data === void 0 ? void 0 : (_data$viewer = data.viewer) === null || _data$viewer === void 0 ? void 0 : _data$viewer.store) || null;
  var defaultProduct = (data === null || data === void 0 ? void 0 : (_data$computeProductL = data.computeProductList) === null || _data$computeProductL === void 0 ? void 0 : (_data$computeProductL2 = _data$computeProductL.data) === null || _data$computeProductL2 === void 0 ? void 0 : _data$computeProductL2[0]) || null;
  var groupBuyProduct = (store === null || store === void 0 ? void 0 : (_store$groupBuyEvent = store.groupBuyEvent) === null || _store$groupBuyEvent === void 0 ? void 0 : _store$groupBuyEvent.productOnSale) || null;
  var product = router.pathname === '/events/group-buy/[eventId]' ? groupBuyProduct : defaultProduct;
  return {
    i18n: i18n,
    router: router,
    client: client,
    storeName: (store === null || store === void 0 ? void 0 : (_store$description = store.description) === null || _store$description === void 0 ? void 0 : _store$description.name) || '',
    storeDescription: (store === null || store === void 0 ? void 0 : (_store$description2 = store.description) === null || _store$description2 === void 0 ? void 0 : _store$description2.introduction) || '',
    logoImage: store === null || store === void 0 ? void 0 : store.logoImage,
    faviconImage: store === null || store === void 0 ? void 0 : store.faviconImage,
    backToTopButtonEnabled: (store === null || store === void 0 ? void 0 : (_store$setting = store.setting) === null || _store$setting === void 0 ? void 0 : _store$setting.backToTopButtonEnabled) || false,
    product: product,
    loading: loading,
    lcpImage: (store === null || store === void 0 ? void 0 : (_store$defaultHomePag = store.defaultHomePage) === null || _store$defaultHomePag === void 0 ? void 0 : _store$defaultHomePag.lcpImage) || (store === null || store === void 0 ? void 0 : (_store$customPage = store.customPage) === null || _store$customPage === void 0 ? void 0 : _store$customPage.lcpImage),
    page: useMemo(function () {
      var _store$groupBuyEvent2, _store$groupBuyEvent3;

      var page = (store === null || store === void 0 ? void 0 : store.defaultHomePage) || (store === null || store === void 0 ? void 0 : store.customPage) || (product === null || product === void 0 ? void 0 : product.page) || (store === null || store === void 0 ? void 0 : store.defaultProductListPage) || (store === null || store === void 0 ? void 0 : (_store$groupBuyEvent2 = store.groupBuyEvent) === null || _store$groupBuyEvent2 === void 0 ? void 0 : (_store$groupBuyEvent3 = _store$groupBuyEvent2.product) === null || _store$groupBuyEvent3 === void 0 ? void 0 : _store$groupBuyEvent3.page);
      var blocks = ((page === null || page === void 0 ? void 0 : page.blocks) || []).filter(function (_ref) {
        var releaseDateTime = _ref.releaseDateTime;
        return !releaseDateTime || parseInt(releaseDateTime, 10) * 1000 <= new Date().getTime();
      }).map(function (_ref2) {
        var width = _ref2.width,
            componentWidth = _ref2.componentWidth,
            widgets = _ref2.widgets,
            block = _objectWithoutProperties(_ref2, ["width", "componentWidth", "widgets"]);

        return _objectSpread(_objectSpread({}, block), {}, {
          width: width || 100,
          componentWidth: componentWidth || 0,
          widgets: getJoinedModule(modifyWidgetDataInClient(widgets, router.query, page), {
            query: router.query,
            product: product
          })
        });
      });
      return !page ? null : _objectSpread(_objectSpread({}, page), {}, {
        blocks: blocks,
        // 將 nested blocks 打成平面的，如此可以知道所有 widget 的順序
        flattenedWidgets: flattenWidgets(blocks)
      });
    }, [store, product, router.query])
  };
};

export default usePage;