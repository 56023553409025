import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  query getCart {\n    viewer {\n      id\n      role\n      ...useCartFragment\n      ...useInitialCartFragment\n    }\n  }\n\n  ", "\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  fragment useInitialCartFragment on User {\n    id\n    guestCart @client {\n      ... on GuestCart {\n        cartItems {\n          ...useMergeCartFragment\n        }\n      }\n    }\n  }\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

// import
import { gql } from '@apollo/client'; // graphql import

import { useCartFragment } from '@meepshop/hooks/lib/gqls/useCart';
import { useMergeCartFragment } from '@meepshop/hooks/lib/gqls/useMergeCart'; // definition

export var useInitialCartFragment = gql(_templateObject(), useMergeCartFragment);
export var getCart = gql(_templateObject2(), useCartFragment, useInitialCartFragment);