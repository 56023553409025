"use strict";

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.date.to-string.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.regexp.to-string.js");

require("core-js/modules/es.string.replace.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var formatTransactionFee = function formatTransactionFee(_ref) {
  var method = _ref.method,
      value = _ref.value,
      currencySymbol = _ref.currencySymbol;
  if (typeof method !== 'number') return ''; // 0: 減少手續費（元）
  // 1: 增加手續費（元）
  // 2: 減少手續費 (%)
  // 3: 增加手續費 (%)

  var isInPercentage = [2, 3].includes(method);
  var isDeduction = [0, 2].includes(method) && value > 0;
  var currency = isInPercentage ? '' : "".concat(currencySymbol, " ");
  var prefix = isDeduction ? '-' : '';
  var suffix = isInPercentage ? '%' : '';
  return "".concat(prefix).concat(currency).concat(value === null || value === void 0 ? void 0 : value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')).concat(suffix);
};

var _default = formatTransactionFee;
exports["default"] = _default;