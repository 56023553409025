"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.map.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resolvers = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// graphql typescript
// typescript definition
// definition
var resolvers = {
  OrderEdge: {
    node: function node(_ref) {
      var _node = _ref.node,
          getOrderApplyList = _ref.getOrderApplyList;
      return !_node ? null : _objectSpread(_objectSpread({}, _node), {}, {
        getOrderApplyList: getOrderApplyList
      });
    }
  },
  OrderConnection: {
    edges: function edges(_ref2) {
      var _edges = _ref2.edges,
          getOrderApplyList = _ref2.getOrderApplyList;
      return _edges === null || _edges === void 0 ? void 0 : _edges.map(function (edge) {
        return !edge ? null : _objectSpread(_objectSpread({}, edge), {}, {
          getOrderApplyList: getOrderApplyList
        });
      });
    }
  },
  User: {
    order: function order(_ref3) {
      var _order = _ref3.order,
          getOrderApplyList = _ref3.getOrderApplyList;
      return !_order ? null : _objectSpread(_objectSpread({}, _order), {}, {
        getOrderApplyList: getOrderApplyList
      });
    },
    orders: function orders(_ref4) {
      var _orders = _ref4.orders,
          getOrderApplyList = _ref4.getOrderApplyList;
      return !_orders ? null : _objectSpread(_objectSpread({}, _orders), {}, {
        getOrderApplyList: getOrderApplyList
      });
    }
  },
  Query: {
    viewer: function viewer(_ref5) {
      var _viewer = _ref5.viewer,
          getOrderApplyList = _ref5.getOrderApplyList;
      return !_viewer ? null : _objectSpread(_objectSpread({}, _viewer), {}, {
        getOrderApplyList: getOrderApplyList
      });
    }
  }
};
exports.resolvers = resolvers;