"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.string.link.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _AdTrack = _interopRequireDefault(require("@meepshop/context/lib/AdTrack"));

// import
// definition
var _default = function _default(goToButton) {
  var adTrack = (0, _react.useContext)(_AdTrack["default"]);
  return (0, _react.useCallback)(function () {
    var _goToButton$link, _goToButton$link$grou, _goToButton$link2, _goToButton$link3, _goToButton$link3$tra, _goToButton$link4, _goToButton$link4$tra;

    var dom = document.getElementById("block-".concat(goToButton === null || goToButton === void 0 ? void 0 : (_goToButton$link = goToButton.link) === null || _goToButton$link === void 0 ? void 0 : (_goToButton$link$grou = _goToButton$link.group) === null || _goToButton$link$grou === void 0 ? void 0 : _goToButton$link$grou.id));

    if (dom) {
      var headerOffset = 45;
      var elementPosition = dom.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }

    if (goToButton !== null && goToButton !== void 0 && (_goToButton$link2 = goToButton.link) !== null && _goToButton$link2 !== void 0 && _goToButton$link2.tracking) adTrack.custom('meepShop_click', (goToButton === null || goToButton === void 0 ? void 0 : (_goToButton$link3 = goToButton.link) === null || _goToButton$link3 === void 0 ? void 0 : (_goToButton$link3$tra = _goToButton$link3.tracking) === null || _goToButton$link3$tra === void 0 ? void 0 : _goToButton$link3$tra.name) || '', (goToButton === null || goToButton === void 0 ? void 0 : (_goToButton$link4 = goToButton.link) === null || _goToButton$link4 === void 0 ? void 0 : (_goToButton$link4$tra = _goToButton$link4.tracking) === null || _goToButton$link4$tra === void 0 ? void 0 : _goToButton$link4$tra.category) || null);
  }, [goToButton, adTrack]);
};

exports["default"] = _default;