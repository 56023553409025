import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query getCart {\n    viewer {\n      id\n      ...useCartFragment\n    }\n  }\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

// import
import { gql } from '@apollo/client'; // graphql import

import { useCartFragment } from '@meepshop/hooks/lib/gqls/useCart'; // definition

export var getCart = gql(_templateObject(), useCartFragment);