"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.promise.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _dynamic = _interopRequireDefault(require("next/dynamic"));

var _default = {
  Default: (0, _dynamic["default"])(function () {
    return import(
    /* webpackChunkName: "MeepUiProductList" */
    "./ProductList");
  }, {
    loadableGenerated: {
      webpack: function webpack() {
        return [require.resolveWeak("./ProductList")];
      },
      modules: ["../../../meep-ui/lib/productList/index.js -> " + "./ProductList"]
    }
  }),
  Controlled: (0, _dynamic["default"])(function () {
    return import(
    /* webpackChunkName: "MeepUiProductListControlled" */
    "./ProductListControlled");
  }, {
    loadableGenerated: {
      webpack: function webpack() {
        return [require.resolveWeak("./ProductListControlled")];
      },
      modules: ["../../../meep-ui/lib/productList/index.js -> " + "./ProductListControlled"]
    }
  })
};
exports["default"] = _default;