"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.find-index.js");

require("core-js/modules/es.array.splice.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _client = require("@apollo/client");

var _log = require("@meepshop/logger/lib/gqls/log");

// import
// graphql import
// definition
var _default = function _default() {
  var _useMutation = (0, _client.useMutation)(_log.log),
      _useMutation2 = (0, _slicedToArray2["default"])(_useMutation, 1),
      mutation = _useMutation2[0];

  return (0, _react.useCallback)(function (cartItems, _ref) {
    var productId = _ref.productId,
        quantity = _ref.quantity,
        variantId = _ref.variantId;
    var newCartItems = (0, _toConsumableArray2["default"])(cartItems);
    var cartItemIndex = newCartItems.findIndex(function (item) {
      return item.variantId === variantId;
    });
    var cartItem = newCartItems[cartItemIndex];
    if (!cartItem) newCartItems.push({
      __typename: 'CartItem',
      productId: productId,
      quantity: quantity,
      variantId: variantId
    });else cartItem.quantity += quantity;
    if ((cartItem === null || cartItem === void 0 ? void 0 : cartItem.quantity) === 0) newCartItems.splice(cartItemIndex, 1);

    if ((cartItem === null || cartItem === void 0 ? void 0 : cartItem.quantity) < 0) {
      newCartItems.splice(cartItemIndex, 1);
      mutation({
        variables: {
          input: {
            type: 'INFO',
            name: 'MERGE_CART_ERROR',
            data: {
              cartItems: cartItems
            }
          }
        }
      });
    }

    return newCartItems;
  }, [mutation]);
};

exports["default"] = _default;