"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.reduce.js");

require("core-js/modules/es.object.entries.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//  Functions used for tranforming or mutating objects
// Transform empty string value to null
var nullify = function nullify(obj) {
  var nullifiedObj = Object.entries(obj).reduce(function (prev, current) {
    var _current = (0, _slicedToArray2["default"])(current, 2),
        key = _current[0],
        value = _current[1];

    var result = value || null;
    return _objectSpread(_objectSpread({}, prev), {}, (0, _defineProperty2["default"])({}, key, result));
  }, {});
  return nullifiedObj;
};

var _default = {
  nullify: nullify
};
exports["default"] = _default;