"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.array.for-each.js");

require("core-js/modules/web.dom-collections.for-each.js");

require("core-js/modules/web.timers.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _react = require("react");

var _link = require("@meepshop/link");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// definition
var _default = function _default(_ref) {
  var store = _ref.store,
      fbq = _ref.fbq,
      pageId = _ref.pageId,
      snapshotId = _ref.snapshotId;
  var router = (0, _link.useRouter)();
  var prevAsPathRef = (0, _react.useRef)('');
  var prevSnapshotIdRef = (0, _react.useRef)('');
  var timeoutsRef = (0, _react.useRef)([]);
  (0, _react.useEffect)(function () {
    // Function to clear all active timeouts
    var clearAllTimeouts = function clearAllTimeouts() {
      timeoutsRef.current.forEach(function (timeoutId) {
        return clearTimeout(timeoutId);
      });
      timeoutsRef.current = [];
    };

    if (!store || prevAsPathRef.current === router.asPath || prevAsPathRef.current !== router.asPath && prevSnapshotIdRef.current === snapshotId || !snapshotId || !pageId) return undefined; // 切換頁面時需要清掉前一頁還沒清掉的 timer

    clearAllTimeouts();

    var _ref2 = store.setting || {},
        _ref2$adRetentionSeco = _ref2.adRetentionSeconds,
        adRetentionSeconds = _ref2$adRetentionSeco === void 0 ? [] : _ref2$adRetentionSeco;

    var googleAnalyticsId = store.adTracks.googleAnalyticsId; // 預設畫面上近來會先送一個 0 秒的 retention 事件，模擬 page_view 事件（因預設 page_view 事件是所有網頁的事件總和，沒有與我們報表上要呈現以 snapshot 為單位的總和）作為計算該次的 retention 總和

    var finalAdRetentionSeconds = [0].concat((0, _toConsumableArray2["default"])(adRetentionSeconds));

    var _loop = function _loop(i) {
      var currentRetentionSeconds = finalAdRetentionSeconds[i];
      var timer = setTimeout(function () {
        fbq('trackCustom', 'meepShop_retention');
        if (window.gtag && googleAnalyticsId) window.gtag('event', 'meepShop_retention', _objectSpread(_objectSpread(_objectSpread({
          event_label: currentRetentionSeconds
        }, pageId ? {
          page_id: pageId
        } : {}), snapshotId ? {
          snapshot_id: snapshotId
        } : {}), {}, {
          non_interaction: true
        }));
        clearTimeout(timer);
      }, currentRetentionSeconds * 1000);
      timeoutsRef.current.push(timer);
    };

    for (var i = 0; i < finalAdRetentionSeconds.length; i += 1) {
      _loop(i);
    }

    prevSnapshotIdRef.current = snapshotId;
    prevAsPathRef.current = router.asPath;
    return function () {
      clearAllTimeouts();
    };
  }, [store, fbq, router.asPath, pageId, snapshotId]);
};

exports["default"] = _default;