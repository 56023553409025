"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resolvers = void 0;

var _parseRawContent = require("../utils/parseRawContent");

// typescript import
// import
// definition
var resolvers = function resolvers(logger) {
  return {
    SettingObjectType: {
      shopperLoginMessageDraft: function shopperLoginMessageDraft(_ref) {
        var shopperLoginMessage = _ref.shopperLoginMessage;

        try {
          return (0, _parseRawContent.parseRawContent)(shopperLoginMessage);
        } catch (error) {
          // FIXME: remove after all draft-js is formatted
          logger.error({
            name: 'FORMAT_DRAFT_ERROR',
            shopperLoginMessage: shopperLoginMessage,
            error: error
          });
          return null;
        }
      }
    }
  };
};

exports.resolvers = resolvers;