"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CURRENCY_SYMBOL = void 0;
var CURRENCY_SYMBOL = {
  TWD: 'NT$',
  CNY: 'RMB￥',
  USD: 'US$',
  JPY: 'JPY￥',
  EUR: '€',
  VND: '₫',
  KRW: '₩',
  HKD: 'HK$',
  MYR: 'RM',
  SGD: 'S$'
};
exports.CURRENCY_SYMBOL = CURRENCY_SYMBOL;