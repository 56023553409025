"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _config = _interopRequireDefault(require("next/config"));

// typescript import
// definition
var _getConfig = (0, _config["default"])(),
    ENV = _getConfig.publicRuntimeConfig.ENV;

var _default = function _default(urls) {
  return urls[ENV];
};

exports["default"] = _default;