"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.array.index-of.js");

require("core-js/modules/es.array.reduce.js");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.search.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _config = _interopRequireDefault(require("next/config"));

var _fetchWithRetries = _interopRequireDefault(require("fbjs/lib/fetchWithRetries"));

var _constants = require("./constants");

var _getConfig;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// definition
// FIXME: remove getConfig after removing express
var LOG_LEVEL = ((_getConfig = (0, _config["default"])()) === null || _getConfig === void 0 ? void 0 : _getConfig.publicRuntimeConfig.LOG_LEVEL) || process.env.LOG_LEVEL || 'info';

var _default = _constants.LOG_TYPES.reduce(function (result, type) {
  return _objectSpread(_objectSpread({}, result), {}, (0, _defineProperty2["default"])({}, type, function (data) {
    if (_constants.LOG_TYPES.indexOf(type) < _constants.LOG_TYPES.indexOf(LOG_LEVEL)) return undefined;
    return process.env.NODE_ENV !== 'production' ? // eslint-disable-next-line no-console
    console[type === 'fatal' ? 'log' : type](data) : (0, _fetchWithRetries["default"])('/api/log', {
      method: 'post',
      headers: {
        'content-type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(_objectSpread(_objectSpread({}, data), {}, {
        url: "".concat(window.location.pathname).concat(window.location.search),
        type: type
      }))
    })["catch"](function () {
      /* ignore error */
    });
  }));
}, {});

exports["default"] = _default;