"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.array.reduce.js");

require("core-js/modules/es.array.some.js");

require("core-js/modules/es.array.sort.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _isLogin = require("../../constants/isLogin");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var mergePages = function mergePages(pages) {
  return pages.reduce(function (result, page) {
    var specificMenuItem = (page.pages || []).filter(function (_ref) {
      var action = _ref.action;
      return [5, 8].includes(action);
    });
    return [].concat((0, _toConsumableArray2["default"])(result), [_objectSpread(_objectSpread({}, page), {}, {
      pages: (page.pages || []).filter(function (_ref2) {
        var action = _ref2.action;
        return ![5, 8].includes(action);
      })
    })], (0, _toConsumableArray2["default"])(specificMenuItem));
  }, []);
};

var _default = function _default(fixedtop, secondtop, sidebar, _ref3) {
  var _fixedtop$menu, _secondtop$menu, _sidebar$menu, _fixedtop$menu2, _fixedtop$menu2$desig, _secondtop$menu2, _secondtop$menu2$desi, _sidebar$menu2, _sidebar$menu2$design, _fixedtop$menu3, _secondtop$menu3, _sidebar$menu3;

  var isLogin = _ref3.isLogin,
      hasStoreAppPlugin = _ref3.hasStoreAppPlugin;
  var design = (fixedtop === null || fixedtop === void 0 ? void 0 : (_fixedtop$menu = fixedtop.menu) === null || _fixedtop$menu === void 0 ? void 0 : _fixedtop$menu.design) || (secondtop === null || secondtop === void 0 ? void 0 : (_secondtop$menu = secondtop.menu) === null || _secondtop$menu === void 0 ? void 0 : _secondtop$menu.design) || (sidebar === null || sidebar === void 0 ? void 0 : (_sidebar$menu = sidebar.menu) === null || _sidebar$menu === void 0 ? void 0 : _sidebar$menu.design);
  var showSearchbar = (fixedtop === null || fixedtop === void 0 ? void 0 : (_fixedtop$menu2 = fixedtop.menu) === null || _fixedtop$menu2 === void 0 ? void 0 : (_fixedtop$menu2$desig = _fixedtop$menu2.design) === null || _fixedtop$menu2$desig === void 0 ? void 0 : _fixedtop$menu2$desig.showSearchbar) || (secondtop === null || secondtop === void 0 ? void 0 : (_secondtop$menu2 = secondtop.menu) === null || _secondtop$menu2 === void 0 ? void 0 : (_secondtop$menu2$desi = _secondtop$menu2.design) === null || _secondtop$menu2$desi === void 0 ? void 0 : _secondtop$menu2$desi.showSearchbar) || (sidebar === null || sidebar === void 0 ? void 0 : (_sidebar$menu2 = sidebar.menu) === null || _sidebar$menu2 === void 0 ? void 0 : (_sidebar$menu2$design = _sidebar$menu2.design) === null || _sidebar$menu2$design === void 0 ? void 0 : _sidebar$menu2$design.showSearchbar);
  return _objectSpread(_objectSpread({}, mergePages([].concat((0, _toConsumableArray2["default"])((fixedtop === null || fixedtop === void 0 ? void 0 : (_fixedtop$menu3 = fixedtop.menu) === null || _fixedtop$menu3 === void 0 ? void 0 : _fixedtop$menu3.pages) || []), (0, _toConsumableArray2["default"])((secondtop === null || secondtop === void 0 ? void 0 : (_secondtop$menu3 = secondtop.menu) === null || _secondtop$menu3 === void 0 ? void 0 : _secondtop$menu3.pages) || []), (0, _toConsumableArray2["default"])((sidebar === null || sidebar === void 0 ? void 0 : (_sidebar$menu3 = sidebar.menu) === null || _sidebar$menu3 === void 0 ? void 0 : _sidebar$menu3.pages) || []))).reduce(function (_ref4, _ref5) {
    var pages = _ref4.pages,
        headerPages = _ref4.headerPages;
    var id = _ref5.id,
        action = _ref5.action,
        page = (0, _objectWithoutProperties2["default"])(_ref5, ["id", "action"]);

    var newPage = _objectSpread(_objectSpread({}, page), {}, {
      id: id,
      action: action
    });

    delete newPage.image;
    if ([].concat((0, _toConsumableArray2["default"])(pages), (0, _toConsumableArray2["default"])(headerPages)).some(function (_ref6) {
      var newPageId = _ref6.id;
      return newPageId === id;
    })) return {
      pages: pages,
      headerPages: headerPages
    };

    if ([5, 8].includes(action)) {
      if (headerPages.some(function (_ref7) {
        var headerPageAction = _ref7.action;
        return headerPageAction === action;
      })) return {
        pages: pages,
        headerPages: headerPages
      };
      return {
        pages: pages,
        headerPages: [].concat((0, _toConsumableArray2["default"])(headerPages), [_objectSpread(_objectSpread({}, newPage), {}, {
          image: {
            __typename: 'DefaultIcon',
            icon: action === 5 ? 'CART' : 'PROFILE'
          },
          imagePosition: 'ONLY',
          pages: action === 5 || isLogin === _isLogin.NOTLOGIN ? [] : [{
            id: 'ORDERS',
            title: null,
            action: 2,
            params: {
              url: '/orders'
            }
          }, {
            id: 'SETTINGS',
            title: null,
            action: 2,
            params: {
              url: '/members/settings'
            }
          }, {
            id: 'RECIPIENTS',
            title: null,
            action: 2,
            params: {
              url: '/members/recipients'
            }
          }, {
            id: 'PASSWORD_CHANGE',
            title: null,
            action: 2,
            params: {
              url: '/members/password-change'
            }
          }].concat((0, _toConsumableArray2["default"])(!hasStoreAppPlugin('wishList') ? [] : [{
            id: 'WISHLIST',
            title: null,
            action: 2,
            params: {
              url: '/members/wishlist'
            }
          }]), [{
            id: 'REWARD_POINTS',
            title: null,
            action: 2,
            params: {
              url: '/members/reward-points'
            }
          }, {
            id: 'LOGOUT',
            title: null,
            action: 'logout'
          }])
        })]).sort(function (a, b) {
          return b.action - a.action;
        })
      };
    }

    return {
      pages: [].concat((0, _toConsumableArray2["default"])(pages), [newPage]),
      headerPages: headerPages
    };
  }, {
    pages: [],
    headerPages: []
  })), {}, {
    design: _objectSpread(_objectSpread({}, design), {}, {
      showSearchbar: showSearchbar
    })
  });
};

exports["default"] = _default;