"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FONTFAMILY = exports.COLORS = void 0;
// typescript import
// typescript definition
// definition
var COLORS = {
  black: '000000',
  white: 'ffffff',
  red: 'ff0000',
  orange: 'ffa500',
  yellow: 'ffff00',
  green: '008000',
  blue: '0000ff',
  purple: '800080'
}; // 前台與後台選項需相同 : source/public/meepshop-ui/components/property-editor-components/draft-text/constants.js , source/public/meepshop-ui/layout-components/draft-text/constants.js

exports.COLORS = COLORS;
var FONTFAMILY = [{
  name: '微軟正黑體',
  family: '微軟正黑體'
}, {
  name: '標楷體',
  family: '標楷體'
}, {
  name: '楷體',
  family: 'cwTeXKai'
}, {
  name: 'PingFang TC',
  family: 'PingFang TC'
}, {
  name: 'Arial',
  family: 'Arial'
}, {
  name: 'Arial Black',
  family: 'Arial Black'
}, {
  name: 'Comic Sans MS',
  family: 'Comic Sans MS'
}, {
  name: 'Courier',
  family: 'Courier'
}, {
  name: 'Courier New',
  family: 'Courier New'
}, {
  name: 'Franklin Gothic',
  family: 'Franklin Gothic'
}, {
  name: 'Georgia',
  family: 'Georgia'
}, {
  name: 'Helvetica',
  family: 'Helvetica'
}, {
  name: 'Impace',
  family: 'Impace'
}, {
  name: 'Lucida Grande',
  family: 'Lucida Grande'
}, {
  name: 'Lucida Scans',
  family: 'Lucida Scans'
}, {
  name: 'Monospace',
  family: 'Monospace'
}, {
  name: 'Tahoma',
  family: 'Tahoma'
}, {
  name: 'Times',
  family: 'Times'
}, {
  name: 'Times New Roman',
  family: 'Times New Roman'
}, {
  name: 'Verdana',
  family: 'Verdana'
}];
exports.FONTFAMILY = FONTFAMILY;