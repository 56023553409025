"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.array.reduce.js");

require("core-js/modules/es.object.keys.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CONTEXT_TYPES = exports.STORE_SETTING_TYPE = exports.CUSTOM_TRACKING_TYPE = exports.ISLOGIN_TYPE = exports.LOCALE_TYPE = exports.SHIPMENT_TEMPLATE_TYPE = exports.PAYMENT_TEMPLATE_TYPE = exports.ALIGNMENT_TYPE = exports.FB_ACCOUNT_TYPE = exports.ONE_OF_LOCALE_TYPE = exports.POSITIVE_FLOAT_TYPE = exports.POSITIVE_NUMBER_TYPE = exports.PURCHASE_ITEMS_TYPE = exports.CONTENT_WIDTH_TYPE = exports.OPACITY_TYPE = exports.COLOR_TYPE = exports.GALLERY_IMAGE_TYPE = exports.IMAGE_TYPE = exports.EMAIL_TYPE = exports.HASH_TYPE = exports.URL_TYPE = exports.FB_ID_TYPE = exports.ID_TYPE = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _isUUID = _interopRequireDefault(require("validator/lib/isUUID"));

var _isNumeric = _interopRequireDefault(require("validator/lib/isNumeric"));

var _isURL = _interopRequireDefault(require("validator/lib/isURL"));

var _isHexColor = _interopRequireDefault(require("validator/lib/isHexColor"));

var _isInt = _interopRequireDefault(require("validator/lib/isInt"));

var _isFloat = _interopRequireDefault(require("validator/lib/isFloat"));

var _isEmail = _interopRequireDefault(require("validator/lib/isEmail"));

var _buildPropTypes = _interopRequireDefault(require("../utils/buildPropTypes"));

var _invoice = require("./invoice");

var ISLOGIN = _interopRequireWildcard(require("./isLogin"));

function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }

function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/** normal propTypes */

/** test uuid */
var ID_TYPE = (0, _buildPropTypes["default"])('id', function (value) {
  return (0, _isUUID["default"])(value);
});
/** test fbID */

exports.ID_TYPE = ID_TYPE;
var FB_ID_TYPE = (0, _buildPropTypes["default"])('fb id', function (value) {
  return (0, _isNumeric["default"])(value);
}, 'number');
/** test
 * https://www.meepshop.com/,
 * www.meepshop.com,
 * /path
 */

exports.FB_ID_TYPE = FB_ID_TYPE;
var URL_TYPE = (0, _buildPropTypes["default"])('url', function (value) {
  return (0, _isURL["default"])(value, {
    require_host: false
  });
});
/** test #hashTag */

exports.URL_TYPE = URL_TYPE;
var HASH_TYPE = (0, _buildPropTypes["default"])('hash', function (value) {
  return /^#/.test(value);
});
/** test test@meepshop.com */

exports.HASH_TYPE = HASH_TYPE;
var EMAIL_TYPE = (0, _buildPropTypes["default"])('email', function (value) {
  return (0, _isEmail["default"])(value);
});
/** testRandom
 * picsum.photos/600/300,
 * picsum.photos/200/550,
 * picsum.photos/400/600
 */

exports.EMAIL_TYPE = EMAIL_TYPE;
var IMAGE_TYPE = (0, _buildPropTypes["default"])('url', function (value) {
  return (0, _isURL["default"])(value, {
    require_host: false
  });
});
exports.IMAGE_TYPE = IMAGE_TYPE;

var GALLERY_IMAGE_TYPE = _propTypes["default"].shape({
  id: ID_TYPE.isRequired,
  isMain: _propTypes["default"].bool.isRequired,
  src: URL_TYPE
});
/** testRandom
 * #ffffff,
 * #000000,
 * #99FF99,
 * inherit,
 * initial
 */


exports.GALLERY_IMAGE_TYPE = GALLERY_IMAGE_TYPE;
var COLOR_TYPE = (0, _buildPropTypes["default"])('color', function (value) {
  return ['inherit', 'initial'].includes(value) || (0, _isHexColor["default"])(value);
});
/** testRandom 0, 0.5, 1 */

exports.COLOR_TYPE = COLOR_TYPE;
var OPACITY_TYPE = (0, _buildPropTypes["default"])('range', function (value) {
  return (0, _isFloat["default"])(value, {
    min: 0,
    max: 1
  });
}, 'float');
/** testRandom
 * 100,
 * 90,
 * 80,
 * 70,
 * 60,
 * 50,
 * 40,
 * 30,
 * 20,
 * 10
 */

exports.OPACITY_TYPE = OPACITY_TYPE;
var CONTENT_WIDTH_TYPE = (0, _buildPropTypes["default"])('content width', function (value) {
  return (0, _isInt["default"])(value, {
    gt: 0,
    max: 100
  });
}, 'number');
/** testRandom
 * 999,
 * 150,
 * 100,
 * 50,
 * 10,
 * 0
 */

exports.CONTENT_WIDTH_TYPE = CONTENT_WIDTH_TYPE;
var PURCHASE_ITEMS_TYPE = (0, _buildPropTypes["default"])('purchase items', function (value) {
  return (0, _isInt["default"])(value, {
    min: 0,
    max: 999
  });
}, 'number');
/** testRandom
 * 1000,
 * 500,
 * 100,
 * 50,
 * 10,
 * 0
 */

exports.PURCHASE_ITEMS_TYPE = PURCHASE_ITEMS_TYPE;
var POSITIVE_NUMBER_TYPE = (0, _buildPropTypes["default"])('positive number', function (value) {
  return (0, _isInt["default"])(value, {
    min: 0
  });
}, 'number');
/** testRandom 0, 0.5, 1 */

exports.POSITIVE_NUMBER_TYPE = POSITIVE_NUMBER_TYPE;
var POSITIVE_FLOAT_TYPE = (0, _buildPropTypes["default"])('positive float', function (value) {
  return (0, _isFloat["default"])(value, {
    min: 0
  });
}, 'number');
/** test oneOfLocale */

exports.POSITIVE_FLOAT_TYPE = POSITIVE_FLOAT_TYPE;

var ONE_OF_LOCALE_TYPE = _propTypes["default"].oneOf(['zh_TW', 'en_US', 'ja_JP', 'vi_VN', 'fr_FR', 'es_ES', 'th_TH', 'id_ID']);

exports.ONE_OF_LOCALE_TYPE = ONE_OF_LOCALE_TYPE;

var FB_ACCOUNT_TYPE = _propTypes["default"].oneOf(['fanPageId', 'personal']);

exports.FB_ACCOUNT_TYPE = FB_ACCOUNT_TYPE;

var ALIGNMENT_TYPE = _propTypes["default"].oneOf(['left', 'center', 'right']);

exports.ALIGNMENT_TYPE = ALIGNMENT_TYPE;

var PAYMENT_TEMPLATE_TYPE = _propTypes["default"].oneOf(['allpay', 'hitrust', 'gmo', 'cathay', 'chinatrust', 'paypal', 'custom']);

exports.PAYMENT_TEMPLATE_TYPE = PAYMENT_TEMPLATE_TYPE;

var SHIPMENT_TEMPLATE_TYPE = _propTypes["default"].oneOf(['ezship', 'gmo', 'allpay', 'blackcat', 'ezcat', 'others']);
/**
 * testJSON [{
 *   "en_US": "en_US",
 *   "zh_TW": "zh_TW",
 *   "ja_JP": "ja_JP",
 *   "vi_VN": "vi_VN"
 * }]
 */


exports.SHIPMENT_TEMPLATE_TYPE = SHIPMENT_TEMPLATE_TYPE;

var LOCALE_TYPE = _propTypes["default"].shape(['en_US', 'zh_TW', 'ja_JP', 'vi_VN', 'fr_FR', 'es_ES', 'th_TH', 'id_ID'].reduce(function (result, locale) {
  return _objectSpread(_objectSpread({}, result), {}, (0, _defineProperty2["default"])({}, locale, locale === 'zh_TW' ? _propTypes["default"].string.isRequired : _propTypes["default"].string));
}, {}));
/** context propTypes */


exports.LOCALE_TYPE = LOCALE_TYPE;

var ISLOGIN_TYPE = _propTypes["default"].oneOf(Object.keys(ISLOGIN).map(function (key) {
  return ISLOGIN[key];
}));

exports.ISLOGIN_TYPE = ISLOGIN_TYPE;

var CUSTOM_TRACKING_TYPE = _propTypes["default"].shape({
  status: _propTypes["default"].bool.isRequired,
  eventLabel: _propTypes["default"].string.isRequired,
  eventCategory: _propTypes["default"].shape({
    status: _propTypes["default"].bool.isRequired,
    value: _propTypes["default"].string.isRequired
  }).isRequired
});

exports.CUSTOM_TRACKING_TYPE = CUSTOM_TRACKING_TYPE;

var STORE_SETTING_TYPE = _propTypes["default"].shape({
  logoUrl: URL_TYPE,
  mobileLogoUrl: URL_TYPE,
  invoice: _propTypes["default"].shape({
    paper: _propTypes["default"].shape(_invoice.PAPER_TYPE.reduce(function (result, key) {
      return _objectSpread(_objectSpread({}, result), {}, (0, _defineProperty2["default"])({}, key, _propTypes["default"].shape({
        isEnabled: _propTypes["default"].bool.isRequired
      }).isRequired));
    }, {})),
    electronic: _propTypes["default"].shape({
      citizenDigitalCertificateCarrier: _propTypes["default"].shape({
        isEnabled: _propTypes["default"].bool.isRequired
      }),
      donation: _propTypes["default"].shape({
        isEnabled: _propTypes["default"].bool.isRequired
      }),
      membershipCarrier: _propTypes["default"].shape({
        isEnabled: _propTypes["default"].bool.isRequired
      }),
      mobileBarCodeCarrier: _propTypes["default"].shape({
        isEnabled: _propTypes["default"].bool.isRequired
      }),
      triplicate: _propTypes["default"].shape({
        isEnabled: _propTypes["default"].bool.isRequired
      }),
      type: _propTypes["default"].oneOf(_invoice.ELECYTONIC_TYPE).isRequired
    })
  }).isRequired,
  storeName: _propTypes["default"].string.isRequired
});
/** TODO remove */


exports.STORE_SETTING_TYPE = STORE_SETTING_TYPE;
var CONTEXT_TYPES = {
  /** context variables from props */
  cname: _propTypes["default"].string,
  isLogin: ISLOGIN_TYPE.isRequired,
  storeSetting: STORE_SETTING_TYPE.isRequired,
  colors: _propTypes["default"].arrayOf(COLOR_TYPE.isRequired).isRequired,
  locale: ONE_OF_LOCALE_TYPE.isRequired,
  carts: _propTypes["default"].shape({}).isRequired,
  cartLoading: _propTypes["default"].bool.isRequired,
  upsertCart: _propTypes["default"].func.isRequired,

  /** context func from DecoratorsRoot */
  hasStoreAppPlugin: _propTypes["default"].func.isRequired,
  transformCurrency: _propTypes["default"].func.isRequired
};
exports.CONTEXT_TYPES = CONTEXT_TYPES;