"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useRetentionFragment = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _client = require("@apollo/client");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment useRetentionFragment on Store {\n    id\n    setting {\n      adRetentionMilliseconds\n      adRetentionMillisecondsEnabled\n      adRetentionSeconds\n    }\n    adTracks {\n      googleAnalyticsId\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

// definition
var useRetentionFragment = (0, _client.gql)(_templateObject());
exports.useRetentionFragment = useRetentionFragment;