"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useUpsertCartUserFragment = exports.upsertCart = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _client = require("@apollo/client");

var _useMergeCart = require("./useMergeCart");

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment useUpsertCartUserFragment on User {\n    __typename\n    id\n    role\n    cart {\n      __typename\n      ... on Cart {\n        cartItems {\n          ...useMergeCartFragment\n        }\n      }\n    }\n\n    guestCart @client {\n      __typename\n      ... on GuestCart {\n        cartItems {\n          ...useMergeCartFragment\n        }\n      }\n    }\n  }\n\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  mutation upsertCart(\n    $isShopper: Boolean!\n    $input: [CartItemInput!]!\n    $guestInput: [CartItemInput!]!\n  ) {\n    upsertCart(input: $input) @include(if: $isShopper) {\n      ...useUpsertCartUpsertCartResponseFragment\n    }\n\n    upsertGuestCart(input: $guestInput) @client {\n      ...useUpsertCartUpsertCartResponseFragment\n    }\n  }\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment useUpsertCartUpsertCartResponseFragment on UpsertCartResponse {\n    ... on OkResponse {\n      message\n    }\n    ... on UnauthenticatedError {\n      message\n    }\n    ... on UnhandledError {\n      message\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

// definition
var useUpsertCartUpsertCartResponseFragment = (0, _client.gql)(_templateObject());
var upsertCart = (0, _client.gql)(_templateObject2(), useUpsertCartUpsertCartResponseFragment);
exports.upsertCart = upsertCart;
var useUpsertCartUserFragment = (0, _client.gql)(_templateObject3(), _useMergeCart.useMergeCartFragment);
exports.useUpsertCartUserFragment = useUpsertCartUserFragment;