"use strict";

require("core-js/modules/web.timers.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function debounce(func, delay) {
  var timeoutId;
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(function () {
      func.apply(void 0, args);
      timeoutId = undefined;
    }, delay);
  };
}

var _default = debounce;
exports["default"] = _default;