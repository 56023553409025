"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _default = function _default(customTracking) {
  return !customTracking ? null : {
    name: customTracking.eventLabel || 'meepShop_click',
    category: !customTracking.eventCategory ? null : customTracking.eventCategory.value
  };
};

exports["default"] = _default;