"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.is-array.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _lodash = _interopRequireDefault(require("lodash.mergewith"));

// import
// definition
var customizer = function customizer(objValue, sourceVal) {
  return Array.isArray(objValue) ? sourceVal : undefined;
};

var _default = function _default(objectSetting, sourceSetting) {
  return (0, _lodash["default"])(objectSetting, sourceSetting, customizer);
};

exports["default"] = _default;