"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _money = _interopRequireDefault(require("money"));

// import
// definition
_money["default"].base = 'USD';
_money["default"].rates = {
  EUR: 0.902088,
  HKD: 7.82205,
  USD: 1,
  SGD: 1.358151,
  TWD: 30.512287,
  JPY: 108.63175,
  VND: 23142.582444,
  KRW: 1174.74,
  MYR: 4.1695,
  CNY: 7.0274
};

var _default = function _default(exchangeRates) {
  (0, _react.useEffect)(function () {
    var _ref = exchangeRates || {},
        base = _ref.base,
        rates = _ref.rates,
        updatedAt = _ref.updatedAt;

    if (!base || !rates || !updatedAt) return;
    _money["default"].base = base;
    _money["default"].rates = rates;
    _money["default"].updatedAt = updatedAt;
  }, [exchangeRates]);
};

exports["default"] = _default;