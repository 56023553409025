"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCartFragment = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _client = require("@apollo/client");

var _useMergeCart = require("./useMergeCart");

var _useUpsertCart = require("./useUpsertCart");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment useCartFragment on User {\n    id\n    role\n    cart {\n      ... on Cart {\n        cartItems {\n          ...useMergeCartFragment\n        }\n      }\n    }\n    guestCart @client {\n      ... on GuestCart {\n        cartItems {\n          ...useMergeCartFragment\n        }\n      }\n    }\n    ...useUpsertCartUserFragment\n  }\n\n  ", "\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

// definition
var useCartFragment = (0, _client.gql)(_templateObject(), _useMergeCart.useMergeCartFragment, _useUpsertCart.useUpsertCartUserFragment);
exports.useCartFragment = useCartFragment;