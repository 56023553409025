"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.array.filter.js");

require("core-js/modules/es.array.join.js");

require("core-js/modules/es.array.map.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = require("react");

var _Currency = _interopRequireDefault(require("@meepshop/context/lib/Currency"));

var _locales = require("@meepshop/locales");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

// definition
var _default = function _default(store, fbq, email) {
  var _useContext = (0, _react.useContext)(_Currency["default"]),
      currency = _useContext.currency;

  var getLanguage = (0, _locales.useGetLanguage)();
  return (0, _react.useCallback)(function (_ref) {
    var products = _ref.products,
        total = _ref.total;
    if (!store) return;
    var adTracks = store.adTracks;
    var googleAnalyticsId = adTracks.googleAnalyticsId,
        googleAdwordsConfig = adTracks.googleAdwordsConfig,
        googleAdwordsBeginCheckout = adTracks.googleAdwordsBeginCheckout;
    fbq('track', 'InitiateCheckout', {
      value: total,
      currency: currency
    });
    if (window.gtag && googleAnalyticsId) window.gtag('event', 'begin_checkout', {
      currency: currency,
      items: products.filter(function (_ref2) {
        var type = _ref2.type;
        return type === 'product';
      }).map(function (_ref3) {
        var id = _ref3.id,
            title = _ref3.title,
            specs = _ref3.specs,
            totalPrice = _ref3.totalPrice,
            quantity = _ref3.quantity,
            sku = _ref3.sku;
        return {
          id: id,
          name: getLanguage(title),
          variant: (specs || []).map(function (spec) {
            return getLanguage(spec === null || spec === void 0 ? void 0 : spec.title);
          }).join('/'),
          price: totalPrice,
          quantity: quantity,
          sku: sku || ''
        };
      }),
      value: total
    });
    if (window.gtag && googleAdwordsConfig && googleAdwordsBeginCheckout) window.gtag('event', 'conversion', _objectSpread({
      send_to: googleAdwordsBeginCheckout
    }, email ? {
      email: email
    } : {}));
  }, [store, fbq, email, currency, getLanguage]);
};

exports["default"] = _default;